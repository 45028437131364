import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';

import { OmniClusterInfo } from '../types';

import { queryKeys } from './query-keys';

export function useOmniCluster<T = OmniClusterInfo>(
  clusterName?: string,
  credentialId?: number,
  queryOptions?: UseQueryOptions<OmniClusterInfo, unknown, T, string[]>
) {
  return useQuery({
    queryFn: () => getOmniCluster(clusterName, credentialId),
    queryKey: queryKeys.cluster(clusterName),
    enabled: !!clusterName,
    ...queryOptions,
  });
}

async function getOmniCluster(clusterName?: string, credentialId?: number) {
  if (!clusterName) {
    throw new Error('Cluster name is required');
  }
  if (!credentialId) {
    throw new Error('Credential Id is required');
  }
  try {
    const { data } = await axios.get<OmniClusterInfo>(
      `omni/${credentialId}/cluster/${clusterName}`
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
