import { FormikHandlers, useFormikContext } from 'formik';

import { RegistryId } from '@/react/portainer/registries/types/registry';
import { useRegistries } from '@/react/portainer/registries/queries/useRegistries';

import { FormControl } from '@@/form-components/FormControl';
import { Input } from '@@/form-components/Input';
import { Option } from '@@/form-components/PortainerSelect';
import { FormSection } from '@@/form-components/FormSection';
import { TextTip } from '@@/Tip/TextTip';

import { FormValues } from './types';
import { RegistrySelector } from './RegistrySelector';

interface Props {
  onBlur: FormikHandlers['handleBlur'];
  onChange(value: Partial<FormValues>): void;
  disabled?: boolean;
  defaultAgentImage?: string;
  defaultUpdaterImage?: string;
}

export function AdvancedSettings({
  onBlur,
  onChange,
  disabled,
  defaultAgentImage,
  defaultUpdaterImage,
}: Props) {
  const { values, setValues } = useFormikContext<FormValues>();

  const registriesQuery = useRegistries<Array<Option<RegistryId>>>({
    select: (registries) => {
      const hasDefaultRegistry = registries.some(
        (registry) => registry.Id === 0
      );
      const defaultRegistry = hasDefaultRegistry
        ? []
        : [{ value: 0, label: 'Docker Hub (anonymous)' }];

      return [
        ...defaultRegistry,
        ...registries.map((registry) => ({
          value: registry.Id,
          label: registry.Name,
        })),
      ];
    },
  });

  const options = registriesQuery.data || [];

  return (
    <FormSection title="Advanced Settings" isFoldable>
      <TextTip className="mb-2" color="blue">
        To update using a private registry, ensure you&apos;re using Edge Agent
        v2.18.1+ and that both the agent image and the portainer-updater image
        are available in your registry. Make sure these images match the
        software version you intend to upgrade to.
      </TextTip>
      <RegistrySelector
        errorMessage=""
        value={values.registryId}
        onBlur={onBlur}
        onChange={(value) => {
          if (value === 0) {
            handleChange({
              agentImage: defaultAgentImage || '',
              updaterImage: defaultUpdaterImage || '',
              registryId: value,
            });
          } else {
            handleChange({
              agentImage: '',
              updaterImage: '',
              registryId: value,
            });
          }
        }}
        disabled={disabled}
        registries={options}
      />

      <FormControl
        label="Agent Image"
        required
        inputId="agent-image"
        errors={
          values.registryId !== 0 && !values.agentImage
            ? 'Agent image is required'
            : undefined
        }
      >
        <Input
          name="agentImage"
          id="agent-image"
          value={values.agentImage}
          onChange={(e) => handleChange({ agentImage: e.target.value })}
          data-cy="agent-image-input"
        />
      </FormControl>

      <FormControl
        label="Updater Image"
        required
        inputId="updater-image"
        errors={
          values.registryId !== 0 && !values.updaterImage
            ? 'Updater image is required'
            : undefined
        }
      >
        <Input
          name="updaterImage"
          id="updater-image"
          value={values.updaterImage}
          onChange={(e) => handleChange({ updaterImage: e.target.value })}
          data-cy="update-image-input"
        />
      </FormControl>
    </FormSection>
  );

  function handleChange(partialValue: Partial<FormValues>) {
    const updatedValue = { ...values, ...partialValue };
    setValues(updatedValue);
    onChange(updatedValue);
  }
}

export default AdvancedSettings;
