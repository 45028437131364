import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';

import { OmniMachineDetails } from '../types';

import { queryKeys } from './query-keys';

type OmniMachineParam = 'clusterName' | 'isAvailable';
type Params = Partial<Record<OmniMachineParam, string>>;

export function useTalosMachines<T = OmniMachineDetails[]>({
  credentialId,
  queryOptions,
  params,
}: {
  credentialId?: number;
  queryOptions?: UseQueryOptions<OmniMachineDetails[], unknown, T, string[]>;
  params?: Params;
}) {
  return useQuery({
    queryKey: queryKeys.machines({ params, credentialId: credentialId || 0 }),
    queryFn: () => getMachines({ params, credentialId }),
    select: queryOptions?.select,
    enabled: !!credentialId,
    retry: 1,
    ...withGlobalError('Failed to fetch Talos machines'),
  });
}

async function getMachines({
  params,
  credentialId,
}: {
  params?: Params;
  credentialId?: number;
}) {
  if (credentialId === undefined) {
    return [];
  }
  try {
    const { data } = await axios.get<OmniMachineDetails[]>(
      `/omni/${credentialId}/machines`,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
