import { Field, Form, Formik } from 'formik';
import { useRouter } from '@uirouter/react';

import { FormControl } from '@@/form-components/FormControl';
import { Input } from '@@/form-components/Input';
import { LoadingButton } from '@@/buttons/LoadingButton';
import { Button } from '@@/buttons';

import { OmniFormValues, CredentialType, credentialTitles } from '../types';

import { validationSchema } from './OmniCredentialsForm.validation';
import { StyledTextArea } from './SSHCredentialsForm/SSHCredentialsPrivateKeyForm';

const defaultInitialValues: OmniFormValues = {
  name: '',
  credentials: {
    serviceAccountKey: '',
    endpoint: '',
  },
};

type Props = {
  selectedProvider: CredentialType;
  isEditing?: boolean;
  isLoading: boolean;
  onSubmit: (formValues: OmniFormValues) => void;
  credentialNames: string[];
  initialValues?: OmniFormValues;
  placeholderText?: string;
};

export function OmniCredentialsForm({
  selectedProvider,
  isEditing = false,
  isLoading,
  onSubmit,
  credentialNames,
  initialValues = defaultInitialValues,
  placeholderText = `e.g. eyIAYW1lIjoiYWxGxNMEtvUEdQIFBSSVZBVEUgS0VZIEJMT0NLLS0tLS0ifQ==`,
}: Props) {
  const router = useRouter();
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema(credentialNames, isEditing)}
      onSubmit={(values) => onSubmit(values)}
      validateOnMount
    >
      {({ values, errors, handleSubmit, isValid, dirty }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit} noValidate>
          <FormControl
            inputId="name"
            label="Credentials name"
            size="medium"
            errors={errors.name}
          >
            <Field
              as={Input}
              name="name"
              id="name"
              value={values.name}
              placeholder={`e.g. ${credentialTitles[selectedProvider]} Staging`}
              data-cy="cloudSettings-apiKeyName"
            />
          </FormControl>
          <FormControl
            inputId="endpoint"
            label="Endpoint URL"
            size="medium"
            tooltip="The 'OMNI_ENDPOINT' URL shown after using omnictl to create a service account"
            errors={errors.credentials?.endpoint}
          >
            <Field
              as={Input}
              // see https://formik.org/docs/guides/arrays#nested-objects for the longer Name
              name="credentials.endpoint"
              autoComplete="off"
              id="endpoint"
              value={values.credentials.endpoint}
              placeholder="https://api.omni.com:443"
              data-cy="cloudSettings-endpoint"
            />
          </FormControl>

          <FormControl
            inputId="service_account_key"
            label="Service account key"
            size="medium"
            tooltip="The 'OMNI_SERVICE_ACCOUNT_KEY' shown after using omnictl to create a service account"
            errors={errors.credentials?.serviceAccountKey}
          >
            <Field
              as={StyledTextArea}
              name="credentials.serviceAccountKey"
              autoComplete="off"
              type="password"
              id="service_account_token"
              value={values.credentials.serviceAccountKey}
              placeholder={placeholderText}
              data-cy="cloudSettings-serviceAccountKey"
            />
          </FormControl>

          <div className="form-group">
            <div className="col-sm-12 mt-3">
              <LoadingButton
                disabled={!isValid || !dirty}
                data-cy="createCredentials-saveButton"
                isLoading={isLoading}
                className="!ml-0"
                loadingText="Saving credentials..."
              >
                {isEditing ? 'Update credentials' : 'Add credentials'}
              </LoadingButton>
              {isEditing && (
                <Button
                  color="default"
                  data-cy="createCredentials-cancelButton"
                  onClick={() =>
                    router.stateService.go(
                      'portainer.settings.sharedcredentials'
                    )
                  }
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
