import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import {
  mutationOptions,
  withGlobalError,
  withInvalidate,
} from '@/react-tools/react-query';

import { SupportDebugSettings } from '../types';

import { queryKeys } from './queryKeys';

export function useUpdateDebugSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    updateDebugSettings,
    mutationOptions(
      withInvalidate(queryClient, [queryKeys.base()]),
      withGlobalError('Unable to update debug settings')
    )
  );
}

async function updateDebugSettings(settings: SupportDebugSettings) {
  try {
    await axios.put('support/debug_log', settings);
  } catch (e) {
    throw parseAxiosError(e as Error, 'Unable to update debug settings');
  }
}
