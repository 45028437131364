import { useField } from 'formik';

import { FormSection } from '@@/form-components/FormSection';
import { SwitchField } from '@@/form-components/SwitchField';

export function KubeRolesSyncSection() {
  const [{ value }, , { setValue }] = useField<boolean>('disableKubeRolesSync');

  return (
    <FormSection title="KubeRolesSync">
      <div className="form-group">
        <div className="col-sm-12">
          <SwitchField
            label="Disable KubeRolesSync for all built-in roles"
            data-cy="kube-settings-disable-kube-roles-sync"
            checked={value}
            name="disableKubeRolesSync"
            onChange={(value) => setValue(value)}
            labelClass="col-sm-3 col-lg-2"
            tooltip="This will disable the sync of kube roles for all built-in roles. The purpose is to allow modifying the existing built-in roles and not have them overwritten by the sync."
          />
        </div>
      </div>
    </FormSection>
  );
}
