import { useRouter } from '@uirouter/react';

import { notifySuccess } from '@/portainer/services/notifications';
import { transformMachineValuesToMachinePayload } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/OmniCreateClusterForm';
import { Environment } from '@/react/portainer/environments/types';

import { UpdateOmniClusterPayload } from '../../HomeView/omni/types';
import { useUpdateCluster } from '../../omni/queries/useUpdateCluster';
import { OmniAddNodesFormValues } from '../types';

export function useOmniAddNodesFormSubmit(
  environment?: Environment,
  credentialId?: number
) {
  const updateClusterMutation = useUpdateCluster(credentialId);
  const router = useRouter();

  async function handleSubmit(values: OmniAddNodesFormValues) {
    const controlPlaneMachines =
      values.machineSets
        .find((machineSet) => machineSet.role === 'control-plane')
        ?.machines?.map(transformMachineValuesToMachinePayload) ?? [];

    const workerMachines =
      values.machineSets
        .find((machineSet) => machineSet.role === 'worker')
        ?.machines?.map(transformMachineValuesToMachinePayload) ?? [];

    const updateClusterPayload: UpdateOmniClusterPayload = {
      cluster: {
        kind: 'Cluster',
        name: environment?.Name ?? '',
      },
      controlPlanesToAdd: {
        kind: 'ControlPlane',
        machines: controlPlaneMachines,
      },
      workersToAdd: {
        kind: 'Workers',
        machines: workerMachines,
      },
    };

    return updateClusterMutation.mutateAsync(updateClusterPayload, {
      onSuccess: () => {
        notifySuccess(
          'Success',
          'Request to add nodes successfully submitted. This might take a few minutes to complete.'
        );
        router.stateService.go('kubernetes.cluster');
      },
    });
  }

  return {
    handleSubmit,
    isSubmitting: updateClusterMutation.isLoading,
  };
}
