import { object, string } from 'yup';

import { isValidUrl } from '@@/form-components/validate-url';

export function noDuplicateNamesSchema(names: string[]) {
  return string()
    .required('Name is required')
    .test('not existing name', 'Name is already in use', (newName) =>
      names.every((name) => name !== newName)
    );
}

export function validationSchema(names: string[], isEditing = false) {
  return object().shape({
    name: noDuplicateNamesSchema(names),
    credentials: object()
      .shape({
        endpoint: string()
          .test(
            'valid API server URL',
            'The endpoint must be a valid URL',
            (value) => isValidUrl(value)
          )
          .required('Endpoint is required'),
        serviceAccountKey: serviceAccountKeyValidation(isEditing),
      })
      .required(),
  });
}

function serviceAccountKeyValidation(isEditing: boolean) {
  if (isEditing) {
    return string();
  }
  return string()
    .required('Service account token is required')
    .test(
      'is-base64',
      'Service account token must be in base64 format',
      (value) => (value ? isBase64(value) : false)
    );
}

function isBase64(str: string): boolean {
  if (str === '' || str.trim() === '') {
    return false;
  }
  try {
    const regex =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
    return regex.test(str);
  } catch (err) {
    return false;
  }
}
