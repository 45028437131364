import { useQuery } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';

import { queryKeys } from './query-keys';

export function useMachineLogs({
  credentialId,
  machineName,
}: {
  credentialId?: number;
  machineName?: string;
}) {
  return useQuery({
    queryKey: queryKeys.machineLogs(machineName),
    queryFn: () => getMachineLogs({ credentialId, machineName }),
    enabled: !!credentialId,
    ...withGlobalError('Failed to fetch machine logs'),
  });
}

export async function getMachineLogs({
  credentialId,
  machineName,
}: {
  credentialId?: number;
  machineName?: string;
}) {
  if (credentialId === undefined || machineName === undefined) {
    return [];
  }
  try {
    const { data } = await axios.get<string>(
      `/omni/${credentialId}/machine/${machineName}/logs`
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
