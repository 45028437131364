import { openSwitchPrompt } from '@@/modals/SwitchPrompt';
import { ModalType } from '@@/modals';
import { buildConfirmButton } from '@@/modals/utils';

export async function confirmStackDelete(message: string) {
  const result = await openSwitchPrompt(
    message,
    'Automatically remove non-persistent volumes',
    {
      confirmButton: buildConfirmButton('Remove', 'danger'),
      modalType: ModalType.Destructive,
      'data-cy': 'confirm-stack-delete-button',
    }
  );

  return result ? { RemoveVolumes: result.value } : undefined;
}
