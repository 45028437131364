import { Activity, ShieldCheck, ShieldX } from 'lucide-react';

import { isoDateFromTimestamp } from '@/portainer/filters/filters';
import { Environment } from '@/react/portainer/environments/types';
import heartbeatup from '@/assets/ico/heartbeat-up.svg?c';
import heartbeatdown from '@/assets/ico/heartbeat-down.svg?c';

import { EnvironmentStatusBadgeItem } from './EnvironmentStatusBadgeItem';

interface Props {
  showLastCheckInDate?: boolean;
  environment: Environment;
  showMTLSStatus?: boolean;
}

export function EdgeIndicator({
  environment,
  showLastCheckInDate = false,
  showMTLSStatus = false,
}: Props) {
  const heartbeat = environment.Heartbeat;
  const MTLSStatus = environment.MTLSStatus ?? { enabled: false, ok: false };

  function getMTLSIcon() {
    if (MTLSStatus.ok) {
      return (
        <ShieldCheck
          className="icon icon-sm space-right !text-green-7"
          aria-hidden="true"
        />
      );
    }

    return (
      <ShieldX
        className="icon icon-sm space-right !text-error-7"
        aria-hidden="true"
      />
    );
  }

  const associated = !!environment.EdgeID;
  if (!associated) {
    return (
      <span role="status" aria-label="edge-status">
        <EnvironmentStatusBadgeItem aria-label="unassociated">
          <span className="whitespace-nowrap">Not associated</span>
        </EnvironmentStatusBadgeItem>
      </span>
    );
  }

  return (
    <span
      role="status"
      aria-label="edge-status"
      className="flex items-center gap-1"
    >
      <EnvironmentStatusBadgeItem
        color={heartbeat ? 'success' : 'danger'}
        icon={heartbeat ? heartbeatup : heartbeatdown}
        aria-label="edge-heartbeat"
      >
        heartbeat
      </EnvironmentStatusBadgeItem>
      {showMTLSStatus && MTLSStatus.enabled && (
        <EnvironmentStatusBadgeItem
          color={MTLSStatus.ok ? 'success' : 'danger'}
          aria-label="edge-mtls"
        >
          {getMTLSIcon()}
          mTLS
        </EnvironmentStatusBadgeItem>
      )}
      {showLastCheckInDate && !!environment.LastCheckInDate && (
        <span
          className="small text-muted vertical-center"
          aria-label="edge-last-checkin"
          title="Last edge check-in"
        >
          <Activity className="icon icon-sm space-right" aria-hidden="true" />
          {isoDateFromTimestamp(environment.LastCheckInDate)}
        </span>
      )}
    </span>
  );
}
