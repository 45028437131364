import { Download } from 'lucide-react';
import { Formik, Form } from 'formik';

import { notifySuccess } from '@/portainer/services/notifications';

import { LoadingButton } from '@@/buttons/LoadingButton';

import { useDownloadSupportBundleMutation } from './useDownloadSupportBundleMutation';
import { validationSchema } from './SupportFileForm.validation';
import { SecurityFieldset } from './SecurityFieldset';
import { SupportDownloadPayload, SupportFileSettings } from './types';

export function SupportFileForm() {
  const downloadMutate = useDownloadSupportBundleMutation();

  const settings: SupportFileSettings = {
    password: '',
    passwordProtect: false,
  };

  return (
    <Formik<SupportFileSettings>
      initialValues={settings}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form className="form-horizontal">
          <SecurityFieldset
            switchDataCy="settings-passwordProtectLocal"
            inputDataCy="settings-backupLocalPassword"
          />

          <div className="form-group">
            <div className="col-sm-12">
              <LoadingButton
                loadingText="Preparing bundle..."
                data-cy="settings-downloadSupportButton"
                isLoading={isSubmitting}
                disabled={!isValid}
                className="!ml-0"
                icon={Download}
              >
                Download support bundle
              </LoadingButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  async function onSubmit(values: SupportFileSettings) {
    const payload: SupportDownloadPayload = {
      password: '',
    };
    if (values.passwordProtect) {
      payload.password = values.password;
    }

    await downloadMutate.mutateAsync(payload, {
      onSuccess() {
        notifySuccess('Success', 'Downloaded support bundle successfully');
      },
    });
  }
}
