/**
 * Extracts the major and minor version from a version string.
 *
 * @param version - The version string to extract from.
 * @returns The major and minor version string.
 *
 * @example
 * getMajorAndMinorString('1.9.0') // returns '1.9'
 * getMajorAndMinorString('v1.10.0') // returns 'v1.10'
 * getMajorAndMinorString('abc1.11.0') // returns 'abc1.11'
 */
export function getMajorAndMinorString(version: string) {
  const [major, minor] = version.split('.');
  return `${major}.${minor}`;
}

/**
 * Validates if a string is a valid IPv4 CIDR or IPv6 CIDR.
 */
export function isValidCIDR(cidr: string) {
  // Basic format check
  if (!cidr || typeof cidr !== 'string') return false;

  const parts = cidr.split('/');
  if (parts.length !== 2) return false;

  const [ip, prefixLengthStr] = parts;
  const prefixLength = parseInt(prefixLengthStr, 10);

  // Check if it's IPv4
  if (ip.includes('.')) {
    // Validate prefix length for IPv4
    if (Number.isNaN(prefixLength) || prefixLength < 0 || prefixLength > 32) {
      return false;
    }

    // Validate IPv4 address
    const octets = ip.split('.');
    if (octets.length !== 4) return false;

    return octets.every((octet) => {
      const num = parseInt(octet, 10);
      return (
        !Number.isNaN(num) &&
        num >= 0 &&
        num <= 255 &&
        // Ensure no leading zeros
        String(num) === octet
      );
    });
  }

  // Check if it's IPv6
  if (ip.includes(':')) {
    // Validate prefix length for IPv6
    if (Number.isNaN(prefixLength) || prefixLength < 0 || prefixLength > 128) {
      return false;
    }

    // Handle empty segments (::)
    const normalizedIP = normalizeIPv6(ip);
    if (!normalizedIP) return false;

    const segments = normalizedIP.split(':');
    if (segments.length !== 8) return false;

    return segments.every((segment) => /^[0-9a-fA-F]{1,4}$/.test(segment));
  }

  return false;
}

function normalizeIPv6(ip: string): string | null {
  // Handle special case of empty address
  if (ip === '::') return '0:0:0:0:0:0:0:0';

  // Split on :: and ensure there's at most one ::
  const parts = ip.split('::');
  if (parts.length > 2) return null;

  if (parts.length === 2) {
    const [left, right] = parts;
    const leftSegments = left ? left.split(':') : [];
    const rightSegments = right ? right.split(':') : [];

    // Calculate how many zero segments we need
    const missingSegments = 8 - (leftSegments.length + rightSegments.length);
    if (missingSegments < 0) return null;

    // Create the zero segments
    const zeros = Array(missingSegments).fill('0');

    // Join all parts together
    return [...leftSegments, ...zeros, ...rightSegments].join(':');
  }

  // No :: compression, just validate segment count
  const segments = ip.split(':');
  if (segments.length !== 8) return null;

  return ip;
}
