import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError, withInvalidate } from '@/react-tools/react-query';
import { environmentQueryKeys } from '@/react/portainer/environments/queries/query-keys';
import { Environment } from '@/react/portainer/environments/types';

import { CreateOmniClusterPayload } from '../../../../portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/types';

import { queryKeys } from './query-keys';

export function useInstallOmniCluster() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      payload,
      credentialId,
    }: {
      payload: CreateOmniClusterPayload;
      credentialId: number;
    }) => installOmniCluster(credentialId, payload),
    ...withInvalidate(queryClient, [
      queryKeys.clusters(),
      queryKeys.base,
      environmentQueryKeys.base(),
    ]),
    ...withGlobalError('Unable to install OmniCluster'),
  });
}

async function installOmniCluster(
  credentialId: number,
  payload: CreateOmniClusterPayload
) {
  try {
    const response = await axios.post<Environment>(
      `/omni/${credentialId}/cluster/create`,
      payload
    );
    return response.data;
  } catch (e) {
    throw parseAxiosError(e as Error);
  }
}
