import { LifeBuoy } from 'lucide-react';

import { Widget, WidgetBody, WidgetTitle } from '@@/Widget';
import { FormSection } from '@@/form-components/FormSection';

import { SupportFileForm } from './SupportFileForm';
import { DebugLogForm } from './DebugLogForm';

export function SupportPanel() {
  return (
    <Widget>
      <WidgetTitle icon={LifeBuoy} title="Portainer support" />
      <WidgetBody>
        <div className="form-horizontal">
          <FormSection title="Support bundle">
            <div className="form-group col-sm-12 text-muted small">
              This will collect a bundle of files from Portainer to help
              business support troubleshoot any issues.
            </div>
            <SupportFileForm />
          </FormSection>
          <FormSection title="Debug log">
            <DebugLogForm />
          </FormSection>
        </div>
      </WidgetBody>
    </Widget>
  );
}
