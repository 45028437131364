import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError, withInvalidate } from '@/react-tools/react-query';

import { UpdateOmniClusterPayload } from '../../HomeView/omni/types';

import { queryKeys } from './query-keys';

export function useUpdateCluster(credentialId?: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (clusterPayload: UpdateOmniClusterPayload) =>
      updateOmniCluster(clusterPayload, credentialId),
    ...withInvalidate(queryClient, [queryKeys.clusters()]),
    ...withGlobalError('Failed to update cluster'),
  });
}

async function updateOmniCluster(
  payload: UpdateOmniClusterPayload,
  credentialId?: number
) {
  if (!credentialId) {
    throw new Error('Credential ID is required');
  }
  try {
    const response = await axios.put(
      `/omni/${credentialId}/cluster/update`,
      payload
    );
    return response.data;
  } catch (e) {
    throw parseAxiosError(e, 'Failed to update cluster');
  }
}
