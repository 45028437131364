import { useFormikContext } from 'formik';
import { kebabCase } from 'lodash';

import { useTalosMachines } from '@/react/kubernetes/cluster/omni/queries/useTalosMachines';
import {
  formatMachineOptions,
  MachineSetNodeSelect,
} from '@/react/kubernetes/cluster/omni/components/MachineSetNodeSelect';
import {
  OmniClusterMachineSet,
  OmniMachineFormValues,
} from '@/react/kubernetes/cluster/omni/types';

import { FormControl } from '@@/form-components/FormControl';
import { isArrayErrorType, isErrorType } from '@@/form-components/formikUtils';

import { OmniCreateClusterFormValues } from './types';

interface Props {
  machineSet: OmniClusterMachineSet;
  index: number;
}

export function MachineSelectInput({ machineSet, index }: Props) {
  const { setFieldValue, errors, values } =
    useFormikContext<OmniCreateClusterFormValues>();
  const machineOptionsQuery = useTalosMachines({
    queryOptions: {
      select: (machines) => ({
        options: formatMachineOptions(machines),
        machines,
      }),
    },
    credentialId: values.credentialId,
    params: {
      isAvailable: 'true',
    },
  });
  const machineOptions = machineOptionsQuery.data?.options ?? [];

  const machineSetErrors = isErrorType(errors.omni?.machineSets?.[index])
    ? errors.omni?.machineSets?.[index]
    : undefined;
  const machinesErrors = isArrayErrorType(machineSetErrors?.machines)
    ? machineSetErrors.machines
    : undefined;

  const inputId = kebabCase(machineSet.name);

  return (
    <FormControl
      key={machineSet.name}
      label={machineSet.name}
      inputId={inputId}
      tooltip={
        machineSet.role === 'control-plane'
          ? 'Control plane nodes manage cluster state and workload scheduling on worker nodes. For high availability, use 3 nodes (or 5 for greater reliability).'
          : undefined
      }
      isLoading={machineOptionsQuery.isLoading}
      errors={
        // validates that at least one control-plane node is required
        machineSet.role === 'control-plane' &&
        typeof errors.omni?.machineSets === 'string'
          ? errors.omni?.machineSets
          : undefined
      }
    >
      <MachineSetNodeSelect
        data-cy={`omniCreateForm-${inputId}`}
        values={values.omni.machineSets[index].machines}
        errors={machinesErrors}
        onChange={(machineValues: OmniMachineFormValues[]) => {
          setFieldValue(`omni.machineSets[${index}].machines`, machineValues);
        }}
        options={machineOptions}
        machineSetRole={values.omni.machineSets[index].role}
        machineSetValues={values.omni.machineSets}
        credentialId={values.credentialId}
        talosVersion={values.omni.talosVersion}
        inputId={inputId}
      />
    </FormControl>
  );
}
