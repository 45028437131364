import { useField } from 'formik';

import { Credential } from '@/react/portainer/settings/sharedCredentials/types';

import { FormControl } from '@@/form-components/FormControl';
import { Option, PortainerSelect } from '@@/form-components/PortainerSelect';

import { useSetAvailableOption } from '../useSetAvailableOption';

interface Props {
  credentials: Credential[];
}

export function CredentialsField({ credentials }: Props) {
  const credentialOptions: Option<number>[] = credentials.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  const [fieldProps, meta, helpers] = useField<number>('credentialId');
  useSetAvailableOption(credentialOptions, fieldProps.value, 'credentialId');

  return (
    <FormControl
      label="Credentials"
      tooltip="Credentials to create your cluster."
      inputId="kaas-credential"
      errors={meta.error}
    >
      <PortainerSelect
        name={fieldProps.name}
        data-cy="kaasCreateForm-credentialSelect"
        disabled={credentialOptions.length <= 1}
        options={credentialOptions}
        value={fieldProps.value}
        onChange={handleChange}
      />
    </FormControl>
  );

  function handleChange(value: number) {
    if (!Number.isNaN(value)) {
      helpers.setValue(value);
    }
  }
}
