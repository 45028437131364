import {
  GroupBase,
  MultiValueGenericProps,
  OptionProps,
  components,
  createFilter,
} from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { Settings } from 'lucide-react';
import { createContext, useContext, useMemo, useState } from 'react';
import { FormikErrors } from 'formik';

import { AutomationTestingProps } from '@/types';

import { PortainerSelect, Option } from '@@/form-components/PortainerSelect';
import { Badge } from '@@/Badge';
import { Icon } from '@@/Icon';
import { Sheet, SheetContent } from '@@/Sheet';

import {
  OmniClusterMachineSet,
  OmniMachineDetails,
  OmniMachineFormValues,
  OmniMachineSetRole,
} from '../types';
import { getMachineFormValue } from '../utils';
import { useTalosMachines } from '../queries/useTalosMachines';

import { MachineConfigFormSection } from './MachineConfigFormSection';

const filterConfig = {
  // stringify the option to make the machine labels searchable
  stringify: (option: FilterOptionOption<unknown>) => JSON.stringify(option),
};

const selectAllOption = {
  label: 'Select all',
  value: 'select-all',
};

const MachineValuesContext = createContext<OmniMachineFormValues[]>([]);

export function MachineSetNodeSelect({
  values,
  errors,
  onChange,
  options,
  machineSetRole,
  machineSetValues,
  'data-cy': dataCy,
  credentialId,
  talosVersion,
  inputId,
}: {
  values: OmniMachineFormValues[];
  errors?: FormikErrors<OmniMachineFormValues>[];
  onChange: (value: OmniMachineFormValues[]) => void;
  options: Option<string>[];
  machineSetRole: OmniMachineSetRole;
  machineSetValues: OmniClusterMachineSet[];
  credentialId: number;
  talosVersion: string;
  inputId: string;
} & AutomationTestingProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [machineName, setMachineName] = useState('');
  const machineIndex = useMemo(
    () => values.findIndex((machine) => machine.name === machineName),
    [machineName, values]
  );
  const machineLabel = options.find((option) => option.value === machineName)
    ?.label;
  const availableOptions = useAvailableMachineOptions(
    machineSetValues,
    values.map((value) => value.name),
    options
  );
  const [newAddressOptions, setNewAddressOptions] = useState<Option<string>[]>(
    []
  );
  const [newGatewayOptions, setNewGatewayOptions] = useState<Option<string>[]>(
    []
  );
  const [newNameserverOptions, setNewNameserverOptions] = useState<
    Option<string>[]
  >([]);

  const machineOptionsQuery = useTalosMachines({
    queryOptions: {
      select: (machines) => ({
        options: formatMachineOptions(machines),
        machines,
      }),
    },
    credentialId,
    params: {
      isAvailable: 'true',
    },
  });
  const machines = machineOptionsQuery.data?.machines ?? [];
  const selectValues = values.map((value) => value.name);

  return (
    <MachineValuesContext.Provider value={values}>
      <Sheet open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <PortainerSelect
          isMulti
          options={
            machineSetRole === 'worker' && availableOptions.length >= 1
              ? [selectAllOption, ...availableOptions]
              : availableOptions
          }
          value={selectValues}
          onChange={(machineNames) => {
            if (machineNames.includes(selectAllOption.value)) {
              onChange(
                availableOptions.map((option) => {
                  const machine = machines.find(
                    (m) => m.machineName === option.value
                  );
                  return getMachineFormValue(option.value, machine, values);
                })
              );
              return;
            }
            const machineValues: OmniMachineFormValues[] = machineNames.map(
              (machineName) => {
                const machine = machines.find(
                  (m) => m.machineName === machineName
                );
                return getMachineFormValue(machineName, machine, values);
              }
            );
            onChange(machineValues);
          }}
          data-cy={dataCy}
          components={{
            Option: CustomOption,
            MultiValueLabel: customMultiValueLabel,
          }}
          filterOption={createFilter(filterConfig)}
          disabled={isOpen}
          inputId={inputId}
        />
        <SheetContent title={`Configure ${machineLabel ?? 'machine'}`}>
          <MachineConfigFormSection
            index={machineIndex}
            machineName={machineName}
            machineLabel={machineLabel ?? machineName}
            credentialId={credentialId}
            machineFormValues={values[machineIndex]}
            errors={errors?.[machineIndex]}
            onChange={(machineFormValues, index) => {
              const newValues = structuredClone(values);
              newValues[index] = machineFormValues;
              onChange(newValues);
            }}
            newAddressOptions={newAddressOptions}
            newGatewayOptions={newGatewayOptions}
            setNewAddressOptions={setNewAddressOptions}
            setNewGatewayOptions={setNewGatewayOptions}
            newNameserverOptions={newNameserverOptions}
            setNewNameserverOptions={setNewNameserverOptions}
            talosVersion={talosVersion}
          />
        </SheetContent>
      </Sheet>
    </MachineValuesContext.Provider>
  );

  function customMultiValueLabel(
    props: MultiValueGenericProps<
      Option<string>,
      true,
      GroupBase<Option<string>>
    >
  ) {
    return (
      <CustomMultiValueLabel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        setIsOpen={setIsOpen}
        setMachineName={setMachineName}
      />
    );
  }
}

function CustomMultiValueLabel({
  data,
  setIsOpen,
  setMachineName,
}: MultiValueGenericProps<Option<string>, true, GroupBase<Option<string>>> & {
  setIsOpen: (value: boolean) => void;
  setMachineName: (value: string) => void;
}) {
  const machineName = data.value;
  const values = useContext(MachineValuesContext);
  const machine = values.find((value) => value.name === machineName);

  return (
    <>
      <div className="self-center text-xs px-1.5 py-0.5 truncate">
        {data.label}
      </div>
      <button
        className="relative shrink-0 px-1.5 text-xs flex items-center justify-center !ml-0 bg-transparent hover:bg-gray-7/50 rounded-sm border-0 inset-0"
        type="button"
        onMouseDown={(e) => {
          e.stopPropagation();
          setIsOpen(true);
          setMachineName(machineName);
        }}
      >
        <Icon icon={Settings} />
        {machine?.applyConfig && (
          <span className="absolute top-0.5 right-0.5 w-1.5 h-1.5 bg-warning-6 rounded-full" />
        )}
      </button>
    </>
  );
}

function CustomOption({ data, ...props }: OptionProps<Option<string>, true>) {
  const labelsText = data.labels as string[] | undefined;
  return (
    <components.Option
      data={data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <div className="break-all">
        {data.label}
        {!!labelsText?.length && (
          <span className="flex flex-wrap gap-2 mt-2">
            {labelsText.map((label) => (
              <Badge type="info" key={label}>
                {label}
              </Badge>
            ))}
          </span>
        )}
      </div>
    </components.Option>
  );
}

function useAvailableMachineOptions(
  machineSetValues: OmniClusterMachineSet[],
  selectedValues: string[],
  machineSetOptions: Option<string>[]
) {
  return useMemo(() => {
    const allValueNames = machineSetValues.flatMap((machineSet) =>
      machineSet.machines.map((machine) => machine.name)
    );
    return machineSetOptions.filter(
      (option) =>
        // keep options that aren't in allValues or are in selectedValues for the current machineSet
        !allValueNames.includes(option.value) ||
        selectedValues.includes(option.value)
    );
  }, [machineSetValues, selectedValues, machineSetOptions]);
}

// omni.sidero.dev/cluster -> cluster
function removeOmniLabelPrefix(label: string) {
  return label.replace('omni.sidero.dev/', '');
}

export function formatMachineOptions(machines: OmniMachineDetails[]) {
  return machines.map((machine) => ({
    label: machine.spec.network?.hostname ?? machine.machineName,
    value: machine.machineName,
    labels: Object.entries(machine.labels)
      .map(([key, value]) =>
        value
          ? `${removeOmniLabelPrefix(key)}: ${value}`
          : removeOmniLabelPrefix(key)
      )
      .sort((a, b) => a.localeCompare(b)),
  }));
}
