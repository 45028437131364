import { array, string, object, SchemaOf, mixed, number, boolean } from 'yup';
import { compact } from 'lodash';

import {
  MachineInterface,
  MachineRoute,
  OmniClusterMachineSet,
  OmniMachineFormValues,
  OmniMachineSetRole,
} from '@/react/kubernetes/cluster/omni/types';
import { validation as urlValidation } from '@/react/portainer/common/PortainerUrlField';
import { validation as addressValidation } from '@/react/portainer/common/PortainerTunnelAddrField';

import { useNameValidation } from '../../shared/NameField';
import { metadataValidation } from '../../shared/MetadataFieldset/validation';

import { CreateOmniClusterFormValues } from './types';
import { isValidCIDR } from './utils';

const validateMachineRoute: SchemaOf<MachineRoute> = object().shape({
  network: string(),
  gateway: string().test('is-valid-ip', 'Invalid IP address', (ip) =>
    isStringValidIP(ip ?? '')
  ),
});

const validateMachineInterface: SchemaOf<MachineInterface> = object().shape({
  interface: string(),
  addresses: array(
    string()
      .test(
        'is-valid-ip',
        `IP address must be in CIDR notation (e.g., '192.168.0.0/24' or '2001:0db8::/32')`,
        (ip) => isValidCIDR(ip ?? '')
      )
      .required('Address is required')
  ),
  routes: array(validateMachineRoute).required('Routes are required'),
});

const validateMachineInterfaceRequired: SchemaOf<MachineInterface> =
  object().shape({
    interface: string().required('Interface is required'),
    addresses: array(
      string()
        .test(
          'is-valid-ip',
          `IP address must be in CIDR notation (e.g., '192.168.0.0/24' or '2001:0db8::/32')`,
          (ip) => isValidCIDR(ip ?? '')
        )
        .required('Address is required')
    ).min(1, 'At least one address is required'),
    routes: array(validateMachineRoute)
      .required('Gateways are required')
      .min(1, 'At least one gateway is required'),
  });

function validateMachine(
  clusterTalosVersion?: string
): SchemaOf<OmniMachineFormValues> {
  return object().shape({
    name: string().required('Machine name is required'),
    applyConfig: boolean().required('Apply config is required'),
    hostname: string(),
    nameservers: array()
      .of(
        string()
          .test('is-valid-ip', 'Invalid IP address', (ip) =>
            isStringValidIP(ip ?? '')
          )
          .required('Nameserver is required')
      )
      .required('Nameservers are required'),
    talosVersion: string().test(
      'talos-version-validation',
      (params) =>
        `The Talos machine version (${params.value}) is not close enough to the cluster Talos version (${clusterTalosVersion}). Please select a Talos machine version that is the same version as the cluster Talos version, or at most 1 minor version less than the cluster Talos version.`,
      (machineTalosVersion) => {
        if (!machineTalosVersion) {
          return true;
        }
        return isTalosVersionCloseEnough(
          [machineTalosVersion],
          clusterTalosVersion
        );
      }
    ),
    interfaces: array()
      .when('applyConfig', {
        is: true,
        then: array().of(validateMachineInterfaceRequired),
        otherwise: array().of(validateMachineInterface),
      })
      // yup doesn't handle types well inside a when block
      .required('Interfaces are required') as unknown as SchemaOf<
      MachineInterface[]
    >,
  });
}

export function validateMachineSet(
  // allow optional talos version to be passed in for validation for each machine set
  talosVersion?: string
): SchemaOf<OmniClusterMachineSet> {
  return object().shape({
    name: string().required('Machine set name is required'),
    role: mixed<OmniMachineSetRole>()
      .oneOf(['control-plane', 'worker'])
      .required('Machine set role is required'),
    machines: array()
      .of(validateMachine(talosVersion))
      .required('Machines are required'),
  });
}

const validationSchema: SchemaOf<CreateOmniClusterFormValues> = object()
  .shape({
    kubernetesVersion: string().required('Kubernetes version is required'),
    talosVersion: string().required('Talos version is required'),
    machineSets: array()
      .of(validateMachineSet())
      .test(
        'min-control-plane',
        'At least one Control Plane machine is required',
        (machineSets) =>
          machineSets?.some((machineSet) => {
            const machines = machineSet.machines ?? [];
            return machineSet.role === 'control-plane' && machines.length > 0;
          }) ?? false
      )
      .required('Machine sets are required'),
    clusterConfig: string(),
    portainerUrl: urlValidation(),
    tunnelServerAddr: addressValidation(),
  })
  .test(
    'talos-version-validation',
    'There is a Talos machine with a Talos version that is not close enough to the cluster Talos version. Please ensure all Talos machines have a Talos version that is the same version as the cluster Talos version, or at most 1 minor version less than the cluster Talos version.',
    (values) => {
      const allMachinesVersions =
        values.machineSets?.flatMap(
          (machineSet) =>
            compact(
              machineSet.machines?.map((machine) => machine.talosVersion)
            ) ?? []
        ) ?? [];
      const { talosVersion } = values;

      if (!talosVersion) {
        return true;
      }

      return isTalosVersionCloseEnough(allMachinesVersions, talosVersion);
    }
  );

function isStringValidIP(ip: string) {
  return isStringValidIPV4(ip) || isStringValidIPv6(ip);
}

function isStringValidIPV4(ip: string) {
  if (!ip) {
    return false;
  }

  // This regex pattern checks:
  // 1. There are four segments, each up to 255.
  // 2. An optional CIDR suffix: /0 - /32.
  const ipv4WithCIDR =
    /^(25[0-5]|2[0-4]\d|[01]?\d?\d)(\.(25[0-5]|2[0-4]\d|[01]?\d?\d)){3}(\/(3[0-2]|[12]\d|[0-9]))?$/;
  return ipv4WithCIDR.test(ip);
}

function isStringValidIPv6(ip: string) {
  if (!ip) {
    return false;
  }

  // This regex pattern checks:
  // 1. Valid compressed/uncompressed IPv6 notation.
  // 2. Optional CIDR (e.g., /0 - /128).
  // It's intentionally simplified and may not catch every corner case.
  // You can refine it further if needed.
  const ipv6WithCIDR =
    /^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|:((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|:((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|:((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|:((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))|:)|(:((:[0-9A-Fa-f]{1,4}){1,7}|:|((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d))))(\/(12[0-8]|1[01]\d|\d?\d))?$/;

  return ipv6WithCIDR.test(ip);
}

export function isTalosVersionCloseEnough(
  allMachineVersions: string[],
  clusterTalosVersion?: string
) {
  if (!clusterTalosVersion) {
    return true;
  }

  // No versions set on machines => no constraints to check
  if (allMachineVersions.length === 0) {
    return true;
  }

  // Extract major/minor from the cluster's version
  const [clusterMajor, clusterMinor] = clusterTalosVersion
    .replace(/^v/, '')
    .split('.')
    .map(Number);

  return allMachineVersions.every((version) => {
    // version might be empty or undefined
    if (!version) {
      return true;
    }

    // Extract major/minor from machine version
    const [machineMajor, machineMinor] = version
      .replace(/^v/, '')
      .split('.')
      .map(Number);

    return (
      clusterMajor === machineMajor &&
      // The machine minor version must be lower than the cluster minor version
      machineMinor <= clusterMinor &&
      // The cluster version must be at most one minor version higher than the machine version.
      clusterMinor - machineMinor <= 1
    );
  });
}

export function useOmniValidation() {
  return object({
    name: useNameValidation(),
    meta: metadataValidation(),
    credentialId: number().required('Credentials are required.'),
    omni: validationSchema,
  });
}
