import { CheckCircleIcon, HelpCircle, Info } from 'lucide-react';
import { ComponentProps } from 'react';

import { Environment } from '@/react/portainer/environments/types';
import { useOmniCluster } from '@/react/kubernetes/cluster/omni/queries/useOmniCluster';
import { OmniClusterPhase } from '@/react/kubernetes/cluster/omni/types';

import { EnvironmentStatusBadgeItem } from '@@/EnvironmentStatusBadgeItem';

const phaseToBadge: Record<
  OmniClusterPhase,
  ComponentProps<typeof EnvironmentStatusBadgeItem>
> = {
  [OmniClusterPhase.RUNNING]: {
    color: 'success',
    icon: CheckCircleIcon,
    children: 'Running',
  },
  [OmniClusterPhase.DESTROYING]: {
    color: 'danger',
    icon: Info,
    children: 'Destroying',
  },
  [OmniClusterPhase.SCALING_DOWN]: {
    color: 'default',
    icon: Info,
    children: 'Scaling down',
  },
  [OmniClusterPhase.SCALING_UP]: {
    color: 'default',
    icon: Info,
    children: 'Scaling up',
  },
  [OmniClusterPhase.UNKNOWN]: {
    color: 'danger',
    icon: HelpCircle,
    children: 'Unknown',
  },
};

export function TalosInfo({ environment }: { environment: Environment }) {
  const credentialId = environment?.CloudProvider?.CredentialID;
  const clusterQuery = useOmniCluster(environment?.Name ?? '', credentialId, {
    enabled: !!environment?.Name,
  });

  if (!clusterQuery.data) {
    return null;
  }
  return (
    <>
      <div className="small text-muted vertical-center">
        TalosVersion {clusterQuery.data.metadata.talos_version}
      </div>
      <EnvironmentStatusBadgeItem
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...phaseToBadge[clusterQuery.data.status.phase]}
      />
      <EnvironmentStatusBadgeItem
        color={
          clusterQuery.data.status.kubernetesAPIReady ? 'success' : 'danger'
        }
      >
        Kubernetetes API{' '}
        {clusterQuery.data.status.kubernetesAPIReady ? 'ready' : 'not ready'}
      </EnvironmentStatusBadgeItem>
      <EnvironmentStatusBadgeItem
        color={
          clusterQuery.data.status.controlplaneReady ? 'success' : 'danger'
        }
      >
        Control plane{' '}
        {clusterQuery.data.status.controlplaneReady ? 'ready' : 'not ready'}
      </EnvironmentStatusBadgeItem>
    </>
  );
}
