import _ from 'lodash';
import { useFormikContext } from 'formik';
import { useMemo, useEffect } from 'react';

import { usePreviousVersions } from '../queries/usePreviousVersions';

import { RollbackOptions } from './RollbackOptions';
import { ScheduledTimeField } from './ScheduledTimeField';
import { useEdgeGroupsEnvironmentIds } from './useEdgeGroupsEnvironmentIds';
import { FormValues } from './types';

interface Props {
  hasTimeZone: boolean;
  hasGroupSelected: boolean;
}

export function RollbackScheduleDetailsFieldset({
  hasTimeZone,
  hasGroupSelected,
}: Props) {
  const { isLoading, previousVersionCount, version, versionError } =
    useSelectVersionOnMount();

  if (isLoading) {
    return null;
  }

  return (
    <div className="mt-3">
      <RollbackOptions
        affectedAgents={previousVersionCount}
        version={version}
        versionError={versionError}
      />

      {hasTimeZone && hasGroupSelected && previousVersionCount === 1 && (
        <ScheduledTimeField />
      )}
    </div>
  );
}

function useSelectVersionOnMount() {
  const {
    values: { groupIds, registryId, agentImage, updaterImage, version },
    setFieldValue,
    setFieldError,
    errors: { version: versionError },
  } = useFormikContext<FormValues>();

  const environmentIdsQuery = useEdgeGroupsEnvironmentIds(groupIds);

  const previousVersionsQuery = usePreviousVersions(
    environmentIdsQuery.data ?? []
  );

  const previousVersions = useMemo(
    () =>
      previousVersionsQuery.data
        ? _.uniq(Object.values(previousVersionsQuery.data))
        : [],
    [previousVersionsQuery.data]
  );

  const previousVersionCount = useMemo(
    () => Object.keys(previousVersions).length,
    [previousVersions]
  );

  useEffect(() => {
    switch (previousVersions.length) {
      case 0:
        setFieldValue('version', '');
        setFieldError('version', 'No rollback options available');
        break;
      case 1:
        setFieldValue('version', previousVersions[0]);
        setFieldError('version', undefined);

        if (registryId === 0 && agentImage !== '' && updaterImage !== '') {
          setFieldValue('agentImage', `portainer/agent:${previousVersions[0]}`);
        }
        break;
      default:
        setFieldError(
          'version',
          'Rollback is not available for these edge group as there are multiple version types to rollback to'
        );
    }
  }, [
    previousVersions,
    setFieldError,
    setFieldValue,
    agentImage,
    updaterImage,
    registryId,
  ]);

  return {
    isLoading: previousVersionsQuery.isLoading,
    versionError,
    version,
    previousVersionCount,
  };
}
