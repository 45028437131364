import { AddonsArgumentType } from '@/react/kubernetes/cluster/microk8s/addons/types';
import { EnvironmentMetadata } from '@/react/portainer/environments/environment.service/create';

import { Option } from '@@/form-components/Input/Select';

import { K8sDistributionType, KaasProvider } from '../../../types';

export type ProvisionOption = KaasProvider | K8sDistributionType;

export type AddonOptionInfo = {
  versionAvailableFrom: string;
  versionAvailableTo: string;
  repository: string;

  tooltip?: string;
  info?: string;
  version?: string;
  placeholder?: string;
  argumentsType: AddonsArgumentType;
  isDefault: boolean;
} & Option<string>;

export interface BaseK8sInstallFormValues {
  credentialId: number;
  name: string;
  meta: EnvironmentMetadata;
}

export const providerTitles: Record<KaasProvider, string> = {
  civo: 'Civo',
  linode: 'Akamai Connected Cloud',
  digitalocean: 'DigitalOcean',
  gke: 'Google Cloud',
  amazon: 'AWS',
  azure: 'Azure',
};

export const k8sInstallTitles: Record<K8sDistributionType, string> = {
  microk8s: 'MicroK8s',
  omni: 'Sidero Omni',
};

export const provisionOptionTitles: Record<ProvisionOption, string> = {
  ...providerTitles,
  ...k8sInstallTitles,
};
