import { useQuery } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';

import { Option } from '@@/form-components/PortainerSelect';

import { K8sDistributionType } from '../../../../types';

import { MicroK8sInfo } from './types';

export function useMicroK8sOptions() {
  return useQuery(['microK8sClusterOptions'], () => getMicroK8sInfo());
}

export async function getMicroK8sInfo() {
  try {
    const { data } = await axios.get<MicroK8sInfo>(
      `/cloud/${K8sDistributionType.MICROK8S}/info`
    );
    return parseInfoResponse(data);
  } catch (e) {
    throw parseAxiosError(e as Error);
  }
}

function parseInfoResponse(response: MicroK8sInfo): MicroK8sInfo {
  const kubernetesVersions = response.kubernetesVersions.map((v) =>
    buildOption(v.value, v.label)
  );
  const availableAddons = response.availableAddons.map((v) => {
    const a = { ...v };
    const o = buildOption(v.label, v.label);
    a.label = o.label;
    a.value = o.value;
    return a;
  });

  return {
    kubernetesVersions,
    availableAddons,
    requiredAddons: response.requiredAddons,
  };
}

function buildOption(value: string, label?: string): Option<string> {
  return { value, label: label ?? value };
}
