import { FormikErrors } from 'formik';
import { Database } from 'lucide-react';
import filesizeParser from 'filesize-parser';

import {
  EnvironmentId,
  StorageClass,
} from '@/react/portainer/environments/types';
import { FeatureId } from '@/react/portainer/feature-flags/enums';
import { useNamespaceVolumes } from '@/react/kubernetes/volumes/queries/useNamespaceVolumes';
import { VolumeViewModel } from '@/react/kubernetes/volumes/ListView/types';
import { Authorized } from '@/react/hooks/useUser';

import { Icon } from '@@/Icon';
import { FormControl } from '@@/form-components/FormControl';
import { FormError } from '@@/form-components/FormError';
import { FormSectionTitle } from '@@/form-components/FormSectionTitle';
import { InputGroup } from '@@/form-components/InputGroup';
import { SwitchField } from '@@/form-components/SwitchField';
import { Input } from '@@/form-components/Input';
import { isErrorType } from '@@/form-components/formikUtils';
import { Select } from '@@/form-components/ReactSelect';

import { ResourceUsageItem } from '../../ResourceUsageItem';
import {
  gigabytesValue,
  terabytesValue,
  megaBytesValue,
} from '../../../resourceQuotaUtils';

import { StorageQuotaFormValues } from './types';

const sizeUnitOptions: {
  label: string;
  value: StorageQuotaFormValues['sizeUnit'];
}[] = [
  { label: 'MB', value: 'M' },
  { label: 'GB', value: 'G' },
  { label: 'TB', value: 'T' },
];

const unitToValueInterface: Record<
  StorageQuotaFormValues['sizeUnit'],
  (value: string | number) => number
> = {
  M: megaBytesValue,
  G: gigabytesValue,
  T: terabytesValue,
};

type Props = {
  value: StorageQuotaFormValues;
  onChange: (value: StorageQuotaFormValues) => void;
  storageClass: StorageClass;
  errors?: string | FormikErrors<StorageQuotaFormValues>;
  isEdit?: boolean;
  environmentId: EnvironmentId;
  namespace: string;
};

export function StorageQuotaItem({
  value: storageQuota,
  onChange,
  storageClass,
  errors,
  isEdit,
  environmentId,
  namespace,
}: Props) {
  const pvcsQuery = useNamespaceVolumes(environmentId, namespace);
  const used = aggresgateStorageUsage(pvcsQuery.data ?? [], storageClass);
  const usedValue = unitToValueInterface[storageQuota.sizeUnit](used);
  const quotaSize = Number(storageQuota.size ?? '0');

  const storageQuotaErrors = isErrorType<StorageQuotaFormValues>(errors)
    ? errors
    : null;

  return (
    <div key={storageClass.Name}>
      <FormSectionTitle>
        <div className="vertical-center text-muted inline-flex gap-1 align-top">
          <Icon icon={Database} className="!mt-0.5 flex-none" />
          <span>{storageClass.Name}</span>
        </div>
      </FormSectionTitle>
      <hr className="mb-0 mt-2 w-full" />
      <Authorized authorizations={['K8sResourcePoolDetailsW']}>
        <div className="form-group mb-4">
          <div className="col-sm-12">
            <SwitchField
              data-cy="k8sNamespaceEdit-storageClassQuota"
              disabled={false}
              label="Enable quota"
              labelClass="col-sm-3 col-lg-2"
              fieldClass="pt-2"
              checked={storageQuota.enabled || false}
              onChange={(enabled) => onChange({ ...storageQuota, enabled })}
              featureId={FeatureId.K8S_RESOURCE_POOL_STORAGE_QUOTA}
            />
          </div>
        </div>
      </Authorized>
      {storageQuota.enabled && (
        <>
          <Authorized authorizations={['K8sResourcePoolDetailsW']}>
            <FormControl label="Maximum usage" required>
              <InputGroup className="col-sm-3 flex min-w-fit">
                <Input
                  required
                  type="number"
                  min={0}
                  onChange={(event) =>
                    onChange({
                      ...storageQuota,
                      size: event.target.value,
                    })
                  }
                  value={storageQuota.size ?? ''}
                  data-cy="namespaceCreate-maxUsage"
                  placeholder="e.g. 20"
                  className="col-sm-3"
                />
                <Select
                  options={sizeUnitOptions}
                  defaultValue={sizeUnitOptions[0]}
                  value={sizeUnitOptions.find(
                    (option) => option.value === storageQuota.sizeUnit
                  )}
                  onChange={(option) =>
                    onChange({
                      ...storageQuota,
                      sizeUnit: option?.value ?? 'M',
                    })
                  }
                  className="!w-20 [&>div]:!w-20"
                  data-cy="namespaceCreate-storageQuotaUnitSelect"
                  id="namespaceCreate-storageQuotaUnitSelect"
                />
              </InputGroup>
              {storageQuotaErrors?.size && (
                <FormError>{storageQuotaErrors.size}</FormError>
              )}
            </FormControl>
          </Authorized>
          {isEdit && (
            <ResourceUsageItem
              value={usedValue}
              total={quotaSize}
              label="Quota usage"
              annotation={`${usedValue} / ${storageQuota.size} ${
                storageQuota.sizeUnit
              }B - ${
                usedValue > quotaSize || (usedValue === 0 && quotaSize === 0)
                  ? 'Exceeded'
                  : `${Math.round((usedValue / quotaSize) * 100)}%`
              }`}
            />
          )}
        </>
      )}
    </div>
  );
}

function aggresgateStorageUsage(
  pvcs: VolumeViewModel[],
  storageClass: StorageClass
) {
  return pvcs.reduce((acc, pvc) => {
    if (pvc.PersistentVolumeClaim.storageClass.Name === storageClass.Name) {
      return (
        acc +
        filesizeParser(pvc.PersistentVolumeClaim.Storage ?? '0', { base: 10 })
      );
    }
    return acc;
  }, 0);
}
