import { useQuery } from '@tanstack/react-query';

import { EnvironmentId } from '@/react/portainer/environments/types';
import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';

export type K8sNodeLimits = {
  CPU: number;
  Memory: number;
};

type NodeLimitsQueryParams = {
  overCommit?: boolean;
  overCommitPercent?: number;
};

/**
 * useClusterResourceLimitsQuery is used to retrieve the total resource limits for a cluster, minus the allocated resources taken by existing namespaces
 * @returns the available resource limits for the cluster
 * */
export function useClusterResourceLimitsQuery(
  environmentId: EnvironmentId,
  params?: NodeLimitsQueryParams
) {
  return useQuery(
    [
      'environments',
      environmentId,
      'kubernetes',
      'max_resource_limits',
      params,
    ],
    () => getResourceLimits(environmentId, params),
    {
      ...withGlobalError('Unable to get resource limits'),
    }
  );
}

async function getResourceLimits(
  environmentId: EnvironmentId,
  params?: NodeLimitsQueryParams
) {
  try {
    const { data: limits } = await axios.get<K8sNodeLimits>(
      `/kubernetes/${environmentId}/max_resource_limits`,
      {
        params,
      }
    );
    return limits;
  } catch (e) {
    throw parseAxiosError(e, 'Unable to retrieve resource limits');
  }
}
