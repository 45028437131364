import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';

import { OmniClusterUpgradeStatus, StatusType } from '../types';

import { queryKeys } from './query-keys';

export function useOmniClusterUpgradeStatus<T = OmniClusterUpgradeStatus>(
  statusType: StatusType,
  clusterName?: string,
  credentialId?: number,
  queryOptions?: UseQueryOptions<OmniClusterUpgradeStatus, unknown, T, string[]>
) {
  return useQuery({
    queryFn: () => getOmniClusterStatus(statusType, clusterName, credentialId),
    queryKey: queryKeys.clusterStatus(statusType, clusterName),
    enabled: !!clusterName && !!credentialId,
    ...queryOptions,
  });
}

async function getOmniClusterStatus(
  statusType: StatusType,
  clusterName?: string,
  credentialId?: number
) {
  if (!clusterName) {
    throw new Error('Cluster name is required');
  }
  if (!credentialId) {
    throw new Error('Credential ID is required');
  }
  try {
    const { data } = await axios.get<OmniClusterUpgradeStatus>(
      `omni/${credentialId}/cluster/${clusterName}/upgrade/status/${statusType}`
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
