import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { semverCompare } from '@/react/common/semver-utils';
import { withGlobalError } from '@/react-tools/react-query';
import { TalosVersions } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/types';

import { Option } from '@@/form-components/PortainerSelect';

import { queryKeys } from './query-keys';

type TalosVersionOptions = {
  talosVersionOptions: Option<string>[];
  compatibleK8sVersionOptions: Record<string, Option<string>[]>;
};

export function useTalosVersionOptions({
  credentialId,
}: {
  credentialId?: number;
}) {
  return useTalosVersions<TalosVersionOptions>({
    credentialId,
    queryOptions: {
      select: (versions) => {
        const talosOptions = Object.keys(versions).sort(
          // by latest version
          (a, b) => semverCompare(b, a)
        );
        const compatibleK8sVersionOptions = Object.fromEntries(
          talosOptions.map((talosVersion) => [
            talosVersion,
            versions[talosVersion]
              .sort(
                // by latest version
                (a, b) => semverCompare(b, a)
              )
              .map((k8sVersion) => ({
                label: k8sVersion,
                value: k8sVersion,
              })),
          ])
        );
        return {
          talosVersionOptions: talosOptions.map((version) => ({
            label: version,
            value: version,
          })),
          compatibleK8sVersionOptions,
        };
      },
      ...withGlobalError('Failed to fetch Talos versions'),
    },
  });
}

function useTalosVersions<T = TalosVersions>({
  credentialId,
  queryOptions,
}: {
  credentialId?: number;
  queryOptions?: UseQueryOptions<TalosVersions, unknown, T, string[]>;
}) {
  return useQuery({
    queryKey: queryKeys.talosVersions(credentialId),
    queryFn: () => getTalosVersions(credentialId),
    enabled: !!credentialId,
    select: queryOptions?.select,
    meta: queryOptions?.meta,
    retry: 1,
  });
}

async function getTalosVersions(credentialId?: number) {
  try {
    // credentialId could be 0, so check for undefined
    if (credentialId === undefined) {
      return {};
    }
    const { data } = await axios.get<TalosVersions>(
      `/omni/${credentialId}/cluster/version/talos`
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
