import { useFormikContext } from 'formik';

import {
  EdgeGroupListItemResponse,
  useEdgeGroups,
} from '@/react/edge/edge-groups/queries/useEdgeGroups';
import { grammaticallyJoin } from '@/portainer/helpers/strings';

import { TextTip } from '@@/Tip/TextTip';

import { FormValues } from './types';

export function RollbackOptions({
  affectedAgents,
  version,
  versionError,
}: {
  affectedAgents: number;
  version: string;
  versionError: string | undefined;
}) {
  const {
    values: { groupIds },
  } = useFormikContext<FormValues>();

  const groupsQuery = useEdgeGroups({
    select: (edgeGroups) => selectGroupNamesAndEnvCount(edgeGroups, groupIds),
  });
  const { groupNames, environmentCount } = groupsQuery.data ?? {};

  if (!affectedAgents) {
    return (
      <TextTip>
        There are no rollback options available for your selected groups(s)
      </TextTip>
    );
  }

  if (versionError) {
    return <TextTip>{versionError}</TextTip>;
  }

  if (groupsQuery.isLoading || !groupNames) {
    return null;
  }

  return (
    <div className="form-group">
      <div className="col-sm-12">
        {environmentCount ?? 0} edge device(s) from {groupNames} will rollback
        to version {version}
      </div>
    </div>
  );
}

function selectGroupNamesAndEnvCount(
  edgeGroups: EdgeGroupListItemResponse[],
  groupIds: number[]
) {
  const selectedEdgeGroups = edgeGroups.filter((g) => groupIds.includes(g.Id));
  const groupNames = grammaticallyJoin(selectedEdgeGroups.map((g) => g.Name));
  // there may be environments in multiple groups, so get the unique count
  const edgeGroupEnvironments = selectedEdgeGroups.flatMap((g) => g.Endpoints);
  const environmentCount = new Set(edgeGroupEnvironments).size;
  return { groupNames, environmentCount };
}
