import { Formik, Form, FormikProps, Field, FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTransitionHook } from '@uirouter/react';
import moment from 'moment';

import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { IngressClassDatatable } from '@/react/kubernetes/cluster/ingressClass/IngressClassDatatable';
import { usePublicSettings } from '@/react/portainer/settings/queries';
import {
  EndpointChangeWindow,
  Environment,
  EnvironmentId,
} from '@/react/portainer/environments/types';
import { useAnalytics } from '@/react/hooks/useAnalytics';
import { TimeWindowPicker } from '@/react/portainer/environments/common/TimeWindowPicker';
import { timeZoneToUtc } from '@/react/portainer/environments/common/TimeWindowPicker/utils';
import { FeatureId } from '@/react/portainer/feature-flags/enums';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { useNamespacesQuery } from '@/react/kubernetes/namespaces/queries/useNamespacesQuery';
import { useResourceQuotasQueries } from '@/react/kubernetes/queries/useResourceQuotasQueries';
import { useClusterResourceLimitsQuery } from '@/react/kubernetes/namespaces/queries/useResourceLimitsQuery';

import { FormSection } from '@@/form-components/FormSection';
import { TextTip } from '@@/Tip/TextTip';
import { SwitchField } from '@@/form-components/SwitchField';
import { FormActions } from '@@/form-components/FormActions';
import { FormControl } from '@@/form-components/FormControl';
import { Input } from '@@/form-components/Input';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { confirm } from '@@/modals/confirm';
import { ModalType } from '@@/modals';
import { buildConfirmButton } from '@@/modals/utils';

import { useIngressControllerClassMapQuery } from '../../ingressClass/useIngressControllerClassMap';
import { IngressControllerClassMap } from '../../ingressClass/types';
import { useIsRBACEnabled } from '../../useIsRBACEnabled';
import { getIngressClassesFormValues } from '../../ingressClass/IngressClassDatatable/utils';

import { useStorageClassesFormValues } from './useStorageClasses';
import { ConfigureFormValues, StorageClassFormValues } from './types';
import { getConfigureValidationSchema } from './validation';
import { RBACAlert } from './RBACAlert';
import { EnableMetricsInput } from './EnableMetricsInput';
import { StorageClassDatatable } from './StorageClassDatatable';
import { useConfigureClusterMutation } from './useConfigureClusterMutation';
import { handleSubmitConfigureCluster } from './handleSubmitConfigureCluster';

export function ConfigureForm() {
  const { trackEvent } = useAnalytics();
  const environmentId = useEnvironmentId();
  // overcommit as false will minus all resource quotas
  const {
    data: clusterResourceLimitsMinusQuotas,
    ...clusterResourceLimitsQueryMinusQuotas
  } = useClusterResourceLimitsQuery(environmentId, {
    overCommit: false,
    overCommitPercent: 0,
  });
  // overCommit as true wont subtract resource quotas
  const { data: clusterResourceLimits, ...clusterLimitsQuery } =
    useClusterResourceLimitsQuery(environmentId, {
      overCommit: true,
      overCommitPercent: 0,
    });
  const isClusterLimitsLoading =
    clusterLimitsQuery.isLoading ||
    clusterResourceLimitsQueryMinusQuotas.isLoading;
  const configureClusterMutation = useConfigureClusterMutation();
  // get the initial values
  const { data: environment } = useCurrentEnvironment();
  const { data: storageClassFormValues } =
    useStorageClassesFormValues(environment);
  const { data: ingressClasses, ...ingressClassesQuery } =
    useIngressControllerClassMapQuery({
      environmentId: environment?.Id,
    });
  const initialValues = useInitialValues(
    environment,
    storageClassFormValues,
    ingressClasses
  );
  const isMissingResourceQuotas = useIsMissingResourceQuotas();

  if (!initialValues || !environment) {
    return null;
  }

  return (
    <Formik<ConfigureFormValues>
      initialValues={initialValues}
      onSubmit={(
        values: ConfigureFormValues,
        formikHelpers: FormikHelpers<ConfigureFormValues>
      ) => {
        handleSubmitConfigureCluster(
          values,
          initialValues,
          configureClusterMutation,
          formikHelpers,
          trackEvent,
          environment
        );
      }}
      validationSchema={getConfigureValidationSchema(
        clusterResourceLimits,
        clusterResourceLimitsMinusQuotas,
        isMissingResourceQuotas
      )}
      validateOnMount
      enableReinitialize // enableReinitialize is needed to update the form values when the ingress classes data is fetched
    >
      {(formikProps) => (
        <InnerForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formikProps}
          isIngressClassesLoading={ingressClassesQuery.isLoading}
          isClusterLimitsLoading={isClusterLimitsLoading}
          environmentId={environment.Id}
        />
      )}
    </Formik>
  );
}

function InnerForm({
  initialValues,
  setFieldValue,
  isValid,
  isSubmitting,
  values,
  dirty,
  errors,
  isIngressClassesLoading,
  isClusterLimitsLoading,
  environmentId,
}: FormikProps<ConfigureFormValues> & {
  isIngressClassesLoading: boolean;
  environmentId: EnvironmentId;
  isClusterLimitsLoading: boolean;
}) {
  const [isTimeWindowEditMode, setIsTimeWindowEditMode] = useState(false);
  const { data: settings } = usePublicSettings();
  const perEnvOverride = settings?.GlobalDeploymentOptions?.perEnvOverride;
  const { data: isRBACEnabled, ...isRBACEnabledQuery } =
    useIsRBACEnabled(environmentId);

  const onChangeControllers = useCallback(
    (controllerClassMap: IngressControllerClassMap[]) =>
      setFieldValue('ingressClasses', controllerClassMap),
    [setFieldValue]
  );

  // when navigating away from the page with unsaved changes, show a portainer prompt to confirm
  useTransitionHook('onBefore', {}, async () => {
    if (!dirty) {
      return true;
    }
    const confirmed = await confirm({
      modalType: ModalType.Warn,
      title: 'Are you sure?',
      message:
        'You currently have unsaved changes in the cluster setup view. Are you sure you want to leave?',
      confirmButton: buildConfirmButton('Yes', 'danger'),
    });
    return confirmed;
  });

  // when reloading or exiting the page with unsaved changes, show a browser prompt to confirm
  useEffect(() => {
    // the handler for showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    function handler(event: BeforeUnloadEvent) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    // if the form is changed, then set the onbeforeunload
    if (dirty) {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    return () => {};
  }, [dirty]);

  return (
    <Form className="form-horizontal">
      <div className="flex flex-col">
        <FormSection title="Networking - Services">
          <div className="form-group">
            <div className="col-sm-12">
              <TextTip color="blue" inline={false}>
                Enabling the load balancer feature will allow users to expose
                applications they deploy over an external IP address assigned by
                the cloud provider.
              </TextTip>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <TextTip color="orange" inline={false}>
                If you want to use this feature, ensure your cloud provider
                allows you to create load balancers. This may incur costs.
              </TextTip>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="useLoadBalancer"
                data-cy="kubeSetup-loadBalancerToggle"
                label="Allow users to use external load balancers"
                labelClass="col-sm-5 col-lg-4"
                checked={values.useLoadBalancer}
                onChange={(checked) =>
                  setFieldValue('useLoadBalancer', checked)
                }
              />
            </div>
          </div>
        </FormSection>
        <FormSection title="Networking - Ingresses">
          <IngressClassDatatable
            onChange={onChangeControllers}
            description="Enabling ingress controllers in your cluster allows them to be available in the Portainer UI for users to publish applications over HTTP/HTTPS. A controller must have a class name for it to be included here."
            values={values.ingressClasses}
            initialValues={initialValues.ingressClasses}
            isLoading={isIngressClassesLoading}
            view="cluster"
            noIngressControllerLabel="No supported ingress controllers found."
          />
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="allowNoneIngressClass"
                data-cy="kubeSetup-allowNoneIngressClass"
                label='Allow ingress class to be set to "none"'
                tooltip='This allows users setting up ingresses to select "none" as the ingress class.'
                labelClass="col-sm-5 col-lg-4"
                checked={values.allowNoneIngressClass}
                onChange={(checked) => {
                  setFieldValue('allowNoneIngressClass', checked);
                  // add or remove the none ingress class from the ingress classes list
                  if (checked) {
                    setFieldValue(
                      'ingressClasses',
                      getIngressClassesFormValues(
                        checked,
                        initialValues.ingressClasses
                      )
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="ingressAvailabilityPerNamespace"
                data-cy="kubeSetup-ingressAvailabilityPerNamespace"
                label="Configure ingress controller availability per namespace"
                tooltip="This allows an administrator to configure, in each namespace, which ingress controllers will be available for users to select when setting up ingresses for applications."
                labelClass="col-sm-5 col-lg-4"
                checked={values.ingressAvailabilityPerNamespace}
                onChange={(checked) =>
                  setFieldValue('ingressAvailabilityPerNamespace', checked)
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="restrictStandardUserIngressW"
                data-cy="kubeSetup-restrictStandardUserIngressWToggle"
                label="Only allow admins to deploy ingresses"
                featureId={FeatureId.K8S_ADM_ONLY_USR_INGRESS_DEPLY}
                tooltip="Enforces only allowing admins to deploy ingresses (and disallows standard users from doing so)."
                labelClass="col-sm-5 col-lg-4"
                checked={values.restrictStandardUserIngressW}
                onChange={(checked) =>
                  setFieldValue('restrictStandardUserIngressW', checked)
                }
              />
            </div>
          </div>
          <TextTip color="blue" className="mb-5">
            You may set up ingress defaults (hostnames and annotations) via
            Create/Edit ingress. Users may then select them via the hostname
            dropdown in Create/Edit application.
          </TextTip>
        </FormSection>
        <FormSection title="Change Window Settings">
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="changeWindow.Enabled"
                data-cy="kubeSetup-changeWindowEnabledToggle"
                label="Enable Change Window"
                tooltip="GitOps updates to stacks or applications outside the defined change window will not occur."
                labelClass="col-sm-5 col-lg-4"
                checked={values.changeWindow.Enabled}
                featureId={FeatureId.HIDE_AUTO_UPDATE_WINDOW}
                onChange={(checked) => {
                  setFieldValue('changeWindow.Enabled', checked);
                  if (checked) {
                    // enable time window edit mode when the change window is enabled
                    setIsTimeWindowEditMode(true);
                    // set a default time window when the change window is enabled
                    const midnightLocalTime = timeZoneToUtc(
                      '00:00',
                      values.timeZone || moment.tz.guess()
                    );
                    const changeWindow: EndpointChangeWindow = {
                      StartTime:
                        values.changeWindow.StartTime || midnightLocalTime,
                      EndTime: values.changeWindow.EndTime || midnightLocalTime,
                      Enabled: checked,
                    };
                    setFieldValue('changeWindow', changeWindow);
                  }
                }}
              />
            </div>
          </div>
          {values.changeWindow.Enabled && (
            <TimeWindowPicker
              values={values.changeWindow}
              initialValues={initialValues.changeWindow}
              onChangeTimeZone={(timeZone) =>
                setFieldValue('timeZone', timeZone)
              }
              onChangeChangeWindow={(changeWindow) =>
                setFieldValue('changeWindow', changeWindow)
              }
              timeZone={values.timeZone}
              initialTimeZone={initialValues.timeZone}
              isEditMode={isTimeWindowEditMode}
              setIsEditMode={setIsTimeWindowEditMode}
              errors={errors.changeWindow}
            />
          )}
        </FormSection>
        {perEnvOverride && (
          <FormSection title="Deployment options">
            <div className="form-group">
              <div className="col-sm-12">
                <SwitchField
                  name="deploymentOptions.overrideGlobalOptions"
                  data-cy="kubeSetup-overrideGlobalDeploymentOptions"
                  label="Override global deployment options"
                  tooltip="Set deployment options for this environment, overriding those from Portainer's global settings screen."
                  labelClass="col-sm-5 col-lg-4"
                  checked={!!values.deploymentOptions?.overrideGlobalOptions}
                  onChange={(checked) =>
                    setFieldValue(
                      'deploymentOptions.overrideGlobalOptions',
                      checked
                    )
                  }
                />
              </div>
            </div>
            {values.deploymentOptions?.overrideGlobalOptions && (
              <>
                <div className="form-group">
                  <div className="col-sm-12">
                    <SwitchField
                      name="deploymentOptions.hideAddWithForm"
                      data-cy="kubesetup-hideAddWithForm"
                      label="Enforce code-based deployment"
                      tooltip="Hides the 'Add with form' buttons and prevents adding/editing of resources via forms."
                      labelClass="col-sm-5 col-lg-4 pr-0 !pl-4"
                      checked={!!values.deploymentOptions?.hideAddWithForm}
                      valueExplanation={
                        <em className="control-label h-full w-max !pt-0 pl-10 align-middle text-[0.9em]">
                          {`${
                            values.deploymentOptions.hideAddWithForm ===
                            settings?.GlobalDeploymentOptions.hideAddWithForm
                              ? 'same as global setting:'
                              : 'overriding global setting:'
                          } ${
                            settings?.GlobalDeploymentOptions.hideAddWithForm
                              ? 'ON'
                              : 'OFF'
                          }`}
                        </em>
                      }
                      onChange={(checked) =>
                        setFieldValue(
                          'deploymentOptions.hideAddWithForm',
                          checked
                        )
                      }
                    />
                  </div>
                </div>
                {values.deploymentOptions.hideAddWithForm && (
                  <>
                    <div className="form-group">
                      <div className="col-sm-12">
                        <SwitchField
                          name="deploymentOptions.hideWebEditor"
                          data-cy="kubesetup-hideWebEditor"
                          label="Allow web editor and custom template use"
                          labelClass="col-sm-5 col-lg-4 pr-0 !pl-4"
                          checked={!values.deploymentOptions?.hideWebEditor}
                          valueExplanation={
                            <em className="control-label h-full w-max !pt-0 pl-10 align-middle text-[0.9em]">
                              {`${
                                !!values.deploymentOptions.hideWebEditor ===
                                !!settings?.GlobalDeploymentOptions
                                  .hideWebEditor
                                  ? 'same as global setting:'
                                  : 'overriding global setting:'
                              } ${
                                !settings?.GlobalDeploymentOptions
                                  .hideWebEditor ||
                                !settings?.GlobalDeploymentOptions.hideWebEditor
                                  ? 'ON'
                                  : 'OFF'
                              }`}
                            </em>
                          }
                          onChange={(checked) =>
                            setFieldValue(
                              'deploymentOptions.hideWebEditor',
                              !checked
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-12">
                        <SwitchField
                          name="deploymentOptions.hideFileUpload"
                          data-cy="kubesetup-allowFileUpload"
                          label="Allow specifying of a manifest via a URL"
                          labelClass="col-sm-5 col-lg-4 pr-0 !pl-4"
                          checked={!values.deploymentOptions?.hideFileUpload}
                          valueExplanation={
                            <em className="control-label h-full w-max !pt-0 pl-10 align-middle text-[0.9em]">{`${
                              !!values.deploymentOptions.hideFileUpload ===
                              !!settings?.GlobalDeploymentOptions.hideFileUpload
                                ? 'same as global setting:'
                                : 'overriding global setting:'
                            } ${
                              !settings?.GlobalDeploymentOptions
                                .hideFileUpload ||
                              !settings?.GlobalDeploymentOptions.hideFileUpload
                                ? 'ON'
                                : 'OFF'
                            }`}</em>
                          }
                          onChange={(checked) =>
                            setFieldValue(
                              'deploymentOptions.hideFileUpload',
                              !checked
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </FormSection>
        )}
        <FormSection title="Security">
          <div className="form-group">
            <div className="col-sm-12">
              {!isRBACEnabled && isRBACEnabledQuery.isSuccess && <RBACAlert />}
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <TextTip color="blue" inline={false}>
                <p>
                  By default, all the users have access to the default
                  namespace. Enable this option to set accesses on the default
                  namespace.
                </p>
              </TextTip>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="restrictDefaultNamespace"
                data-cy="kubeSetup-restrictDefaultNsToggle"
                label="Restrict access to the default namespace"
                labelClass="col-sm-5 col-lg-4"
                checked={values.restrictDefaultNamespace}
                onChange={(checked) =>
                  setFieldValue('restrictDefaultNamespace', checked)
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <SwitchField
                name="restrictSecrets"
                data-cy="kubeSetup-restrictSecretsToggle"
                label="Restrict secret contents access for non-admins (UI only)"
                tooltip="This hides the ability to view or edit in the UI the contents of secrets that a non-admin user did not create themselves but does not prevent it via the command line."
                labelClass="col-sm-5 col-lg-4"
                checked={values.restrictSecrets}
                onChange={(checked) => {
                  setFieldValue('restrictSecrets', checked);
                }}
              />
            </div>
          </div>
        </FormSection>
        <FormSection title="Resources and Metrics">
          <div className="form-group">
            <div className="col-sm-12">
              <TextTip color="blue" inline={false}>
                <p>
                  By DISABLING resource over-commit (highly recommended), you
                  can ONLY assign namespaces CPU and memory resources that are
                  less (in aggregate) than the cluster total minus any system
                  resource reservation.
                </p>
              </TextTip>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <TextTip color="orange" inline={false}>
                <p>
                  By ENABLING resource over-commit, you can assign namespaces
                  more resources than are physically available in the cluster.
                  This may lead to unexpected deployment failures if there are
                  insufficient resources to service demand.
                </p>
              </TextTip>
            </div>
          </div>
          <FormControl
            label="Allow resource over-commit"
            size="large"
            inputId="resource_over_commit"
            errors={errors.enableResourceOverCommit}
          >
            <Field
              name="enableResourceOverCommit"
              as={Switch}
              id="resource_over_commit"
              featureId={FeatureId.K8S_SETUP_DEFAULT}
              checked={values.enableResourceOverCommit}
              onChange={(checked: boolean) => {
                setFieldValue('enableResourceOverCommit', checked);
              }}
              data-cy="kubeSetup-resourceOverCommitToggle"
            />
          </FormControl>
          {!values.enableResourceOverCommit && (
            <FormControl
              label="System resource reservation %"
              size="large"
              inputId="resource_over_commit_percentage"
              errors={errors.resourceOverCommitPercentage}
            >
              <Field
                name="resourceOverCommitPercentage"
                as={Input}
                id="resource_over_commit_percentage"
                className="w-24"
                type="number"
                data-cy="kubeSetup-resourceOverCommitPercentage"
                min={1}
                max={100}
              />
            </FormControl>
          )}
          <EnableMetricsInput
            environmentId={environmentId}
            error={errors.useServerMetrics}
            value={values.useServerMetrics}
          />
        </FormSection>
        <FormSection title="Available storage options">
          {initialValues.storageClasses.length === 0 && (
            <div className="form-group">
              <div className="col-sm-12">
                <TextTip color="orange" className="mb-4" inline={false}>
                  Unable to detect any storage class available to persist data.
                  Users won&apos;t be able to persist application data inside
                  this cluster.
                </TextTip>
              </div>
            </div>
          )}
          {initialValues.storageClasses.length > 0 && (
            <>
              <div className="form-group">
                <div className="col-sm-12">
                  <TextTip color="blue" inline={false}>
                    <p>
                      Select which storage options will be available for use
                      when deploying applications. Have a look at your storage
                      driver documentation to figure out which access policy to
                      configure and if the volume expansion capability is
                      supported.
                    </p>
                    <p>
                      You can find more information about access modes{' '}
                      <a
                        href="https://kubernetes.io/docs/concepts/storage/persistent-volumes/#access-modes"
                        target="_blank"
                        rel="noreferrer"
                      >
                        in the official Kubernetes documentation
                      </a>
                      .
                    </p>
                  </TextTip>
                </div>
              </div>
              <StorageClassDatatable
                storageClassValues={values.storageClasses}
              />
            </>
          )}
        </FormSection>
        <FormActions
          submitLabel="Save configuration"
          loadingText="Saving configuration..."
          isLoading={isSubmitting}
          isValid={
            isValid &&
            !isIngressClassesLoading &&
            dirty &&
            !isClusterLimitsLoading
          }
          data-cy="kubeSetup-saveConfigurationButton"
        />
      </div>
    </Form>
  );
}

function useInitialValues(
  environment?: Environment | null,
  storageClassFormValues?: StorageClassFormValues[],
  ingressClasses?: IngressControllerClassMap[]
): ConfigureFormValues | undefined {
  return useMemo(() => {
    if (!environment) {
      return undefined;
    }
    const allowNoneIngressClass =
      !!environment.Kubernetes.Configuration.AllowNoneIngressClass;

    return {
      storageClasses: storageClassFormValues || [],
      useLoadBalancer: !!environment.Kubernetes.Configuration.UseLoadBalancer,
      useServerMetrics: !!environment.Kubernetes.Configuration.UseServerMetrics,
      enableResourceOverCommit:
        !!environment.Kubernetes.Configuration.EnableResourceOverCommit,
      resourceOverCommitPercentage:
        environment.Kubernetes.Configuration.ResourceOverCommitPercentage || 20,
      restrictDefaultNamespace:
        !!environment.Kubernetes.Configuration.RestrictDefaultNamespace,
      restrictSecrets: !!environment.Kubernetes.Configuration.RestrictSecrets,
      restrictStandardUserIngressW:
        !!environment.Kubernetes.Configuration.RestrictStandardUserIngressW,
      ingressAvailabilityPerNamespace:
        !!environment.Kubernetes.Configuration.IngressAvailabilityPerNamespace,
      allowNoneIngressClass,
      deploymentOptions: environment.DeploymentOptions,
      changeWindow: environment.ChangeWindow,
      ingressClasses:
        getIngressClassesFormValues(allowNoneIngressClass, ingressClasses) ||
        [],
    };
  }, [environment, ingressClasses, storageClassFormValues]);
}

function useIsMissingResourceQuotas() {
  const environmentId = useEnvironmentId();
  const { data: namespaces } = useNamespacesQuery(environmentId);
  const nonSystemNamespaces = useMemo(
    () =>
      namespaces
        ?.filter(
          (namespace) => !namespace.IsSystem && namespace.Name !== 'default'
        )
        .map((namespace) => namespace.Name) ?? [],
    [namespaces]
  );
  const resourceQuotasQueries = useResourceQuotasQueries(
    environmentId,
    nonSystemNamespaces
  );
  const missingResourceQuota = useMemo(
    () =>
      resourceQuotasQueries.some(
        (query) => !query.data || query.data.length === 0
      ),
    [resourceQuotasQueries]
  );
  return missingResourceQuota;
}
