import { Network } from 'lucide-react';
import { Form, FormikErrors } from 'formik';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { compact } from 'lodash';

import { getMajorAndMinorString } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/utils';

import { Widget, WidgetBody, WidgetTitle } from '@@/Widget';
import { FormControl } from '@@/form-components/FormControl';
import { Option, PortainerSelect } from '@@/form-components/PortainerSelect';
import { Button } from '@@/buttons';
import { FormSection } from '@@/form-components/FormSection';
import { Input } from '@@/form-components/Input';
import { TextTip } from '@@/Tip/TextTip';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { isArrayErrorType, isErrorType } from '@@/form-components/formikUtils';

import { OmniMachineDetails, OmniMachineFormValues } from '../types';
import { useTalosMachine } from '../queries/useTalosMachine';

type Props = {
  index: number;
  machineName: string;
  machineLabel: string;
  credentialId: number;
  machineFormValues: OmniMachineFormValues;
  errors?: FormikErrors<OmniMachineFormValues>;
  onChange: (machineFormValues: OmniMachineFormValues, index: number) => void;
  newAddressOptions: Option<string>[];
  newGatewayOptions: Option<string>[];
  newNameserverOptions: Option<string>[];
  setNewAddressOptions: (newAddressOptions: Option<string>[]) => void;
  setNewGatewayOptions: (newGatewayOptions: Option<string>[]) => void;
  setNewNameserverOptions: (newNameserverOptions: Option<string>[]) => void;
  talosVersion: string;
};

/**
 * Form section for configuring the machine network settings.
 * It currently only configures 1 network interface, but it can be extended to support multiple interfaces.
 */
export function MachineConfigFormSection({
  index,
  machineName,
  machineLabel,
  talosVersion,
  credentialId,
  machineFormValues,
  errors,
  onChange,
  newAddressOptions,
  newGatewayOptions,
  newNameserverOptions,
  setNewAddressOptions,
  setNewGatewayOptions,
  setNewNameserverOptions,
}: Props) {
  const machineQuery = useTalosMachine({
    name: machineName,
    credentialId,
  });
  const machine = machineQuery.data;

  if (!machine) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <Widget>
          <WidgetTitle title="Network settings" icon={Network} />
          <WidgetBody>
            <NetworkConfigurationForm
              index={index}
              machine={machine}
              machineFormValues={machineFormValues}
              errors={errors}
              onChange={onChange}
              newAddressOptions={newAddressOptions}
              newGatewayOptions={newGatewayOptions}
              setNewAddressOptions={setNewAddressOptions}
              setNewGatewayOptions={setNewGatewayOptions}
              newNameserverOptions={newNameserverOptions}
              setNewNameserverOptions={setNewNameserverOptions}
              machineLabel={machineLabel}
              talosVersion={talosVersion}
            />
          </WidgetBody>
        </Widget>
      </div>
    </div>
  );
}

interface NetworkConfigurationFormProps {
  index: number;
  machine: OmniMachineDetails;
  machineFormValues: OmniMachineFormValues;
  errors?: FormikErrors<OmniMachineFormValues>;
  onChange: (machineFormValues: OmniMachineFormValues, index: number) => void;
  newAddressOptions: Option<string>[];
  newGatewayOptions: Option<string>[];
  newNameserverOptions: Option<string>[];
  setNewAddressOptions: (newAddressOptions: Option<string>[]) => void;
  setNewGatewayOptions: (newGatewayOptions: Option<string>[]) => void;
  setNewNameserverOptions: (newNameserverOptions: Option<string>[]) => void;
  machineLabel: string;
  talosVersion: string;
}

function NetworkConfigurationForm({
  index,
  machine,
  machineFormValues,
  errors,
  onChange,
  newAddressOptions,
  newGatewayOptions,
  newNameserverOptions,
  setNewAddressOptions,
  setNewGatewayOptions,
  setNewNameserverOptions,
  machineLabel,
  talosVersion,
}: NetworkConfigurationFormProps) {
  const machineAddressOptions =
    machine?.spec.network?.addresses.map((address) => ({
      label: address,
      value: address,
    })) ?? [];
  const addressOptions = [...machineAddressOptions, ...newAddressOptions];
  const machineGatewayOptions =
    machine?.spec.network?.default_gateways.map((gateway) => ({
      label: gateway,
      value: gateway,
    })) ?? [];
  const gatewayOptions = [...machineGatewayOptions, ...newGatewayOptions];
  const interfaceOptions =
    machine?.spec.network?.network_links.map((link) => ({
      label: link.linux_name ?? '',
      value: link.linux_name ?? '',
    })) ?? [];

  const interfaceErrors = isErrorType(errors?.interfaces?.[0])
    ? errors?.interfaces?.[0]
    : undefined;
  const routeErrors = isArrayErrorType(interfaceErrors?.routes)
    ? interfaceErrors?.routes
    : undefined;
  return (
    <Form className="form-horizontal">
      <TextTip color="blue" className="mb-2">
        <p>
          You can manually set the machine network settings here. This will
          override the default Talos machine config for the &apos;{machineLabel}
          &apos; machine generated by Omni. See the{' '}
          <a
            target="_blank"
            href={`https://www.talos.dev/${getMajorAndMinorString(
              talosVersion
            )}/reference/configuration/`}
            rel="noreferrer"
          >
            Talos Config Reference
          </a>{' '}
          for more information.
        </p>
      </TextTip>
      <FormControl label="Override network settings" size="large">
        <Switch
          id="onmiConfigureMachine-applyConfigCheckbox"
          name="onmiConfigureMachine-applyConfigCheckbox"
          checked={machineFormValues.applyConfig}
          onChange={(enabled) => {
            const newConfig = structuredClone(machineFormValues);
            newConfig.applyConfig = enabled;
            onChange(newConfig, index);
          }}
          data-cy="onmiConfigureMachine-applyConfigCheckbox"
        />
      </FormControl>
      {machineFormValues.applyConfig && (
        <>
          <FormControl
            label="Hostname"
            tooltip="Statically set the hostname for the machine"
            size="large"
            errors={errors?.hostname}
          >
            <Input
              value={machineFormValues.hostname}
              onChange={(e) => {
                const newConfig = structuredClone(machineFormValues);
                newConfig.hostname = e.target.value;
                onChange(newConfig, index);
              }}
              data-cy="onmiConfigureMachine-hostnameInput"
            />
          </FormControl>
          <FormControl
            label="Nameservers"
            tooltip="The default DNS nameservers for Talos Linux are 8.8.8.8 and 1.1.1.1"
            size="large"
            errors={
              typeof errors?.nameservers === 'string'
                ? errors?.nameservers
                : compact(errors?.nameservers ?? [])[0] // show the first error, if there are multiple
            }
          >
            <PortainerSelect<string>
              value={machineFormValues.nameservers}
              options={newNameserverOptions}
              noOptionsMessage={() => 'Start typing to add a nameserver'}
              isMulti
              onChange={(nameservers) => {
                // remove any new options that are later removed from the selected values
                const newOptionsInValues = newNameserverOptions.filter(
                  (option) => nameservers.includes(option.value)
                );
                setNewNameserverOptions(newOptionsInValues);

                // update the form values
                const newConfig = structuredClone(machineFormValues);
                newConfig.nameservers = nameservers;
                onChange(newConfig, index);
              }}
              formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
              onCreateOption={handleCreateNameserverOption}
              isCreatable
              data-cy="onmiConfigureMachine-nameserversInput"
            />
          </FormControl>
          <FormSection title="Network interface">
            <FormControl
              label="Network interface name"
              inputId="onmiConfigureMachine-interfaceInput"
              size="large"
            >
              <PortainerSelect<string>
                value={machineFormValues.interfaces[0].interface ?? ''}
                options={interfaceOptions}
                onChange={(interfaceName) => {
                  if (!interfaceName) return;
                  // update the form values
                  const newConfig = structuredClone(machineFormValues);
                  newConfig.interfaces[0].interface = interfaceName;
                  onChange(newConfig, index);
                }}
                data-cy="onmiConfigureMachine-interfaceInput"
              />
            </FormControl>
            <FormControl
              label="IP addresses"
              tooltip="Assign static IP addresses to the interface"
              inputId="onmiConfigureMachine-ipAddressesInput"
              size="large"
              errors={
                typeof interfaceErrors?.addresses === 'string'
                  ? interfaceErrors.addresses
                  : compact(interfaceErrors?.addresses ?? [])[0] // show the first error, if there are multiple
              }
            >
              <PortainerSelect<string>
                value={machineFormValues.interfaces[0].addresses}
                options={addressOptions}
                noOptionsMessage={() => 'Start typing to add an IP address'}
                isMulti
                onChange={(ipAddresses) => {
                  // remove any new options that are later removed from the selected values
                  const newOptionsInValues = newAddressOptions.filter(
                    (option) => ipAddresses.includes(option.value)
                  );
                  setNewAddressOptions(newOptionsInValues);

                  // update the form values
                  const newConfig = structuredClone(machineFormValues);
                  newConfig.interfaces[0].addresses = ipAddresses;
                  onChange(newConfig, index);
                }}
                formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                onCreateOption={handleCreateAddressOption}
                isCreatable
                data-cy="onmiConfigureMachine-ipAddressesInput"
              />
            </FormControl>
            <FormControl
              label="Gateways"
              tooltip="The route's gateway (if empty, creates link scope route)"
              inputId="onmiConfigureMachine-gatewaysInput"
              size="large"
              errors={
                typeof interfaceErrors?.routes === 'string'
                  ? interfaceErrors.routes
                  : compact(routeErrors)[0] // show the first error, if there are multiple
              }
            >
              <PortainerSelect<string>
                value={machineFormValues.interfaces[0].routes.map(
                  (route) => route.gateway ?? ''
                )}
                options={gatewayOptions}
                isMulti
                onChange={(gateways) => {
                  const newConfig = structuredClone(machineFormValues);
                  newConfig.interfaces[0].routes = gateways.map((gateway) => ({
                    gateway,
                    network: '0.0.0.0/0',
                  }));
                  onChange(newConfig, index);
                }}
                noOptionsMessage={() => 'Start typing to add a gateway'}
                formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                onCreateOption={handleCreateGatewayOption}
                isCreatable
                data-cy="onmiConfigureMachine-gatewaysInput"
              />
            </FormControl>
          </FormSection>
        </>
      )}
      <div className="form-section">
        <SheetPrimitive.Close asChild>
          <Button
            onClick={() => {}}
            className="!ml-0"
            data-cy="onmiConfigureMachine-doneButton"
            disabled={
              !!interfaceErrors ||
              !!errors?.hostname ||
              !!errors?.nameservers ||
              !!routeErrors
            }
          >
            Done
          </Button>
        </SheetPrimitive.Close>
      </div>
    </Form>
  );

  function handleCreateAddressOption(inputValue: string) {
    const value = inputValue.trim();
    // Prevent a space from being added as a new option
    if (!value) return;

    setNewAddressOptions([...newAddressOptions, { label: value, value }]);

    const newConfig = structuredClone(machineFormValues);
    newConfig.interfaces[0].addresses = [
      ...newConfig.interfaces[0].addresses,
      value,
    ];
    onChange(newConfig, index);
  }

  function handleCreateGatewayOption(inputValue: string) {
    const value = inputValue.trim();
    // Prevent a space from being added as a new option
    if (!value) return;

    setNewGatewayOptions([...newGatewayOptions, { label: value, value }]);

    const newConfig = structuredClone(machineFormValues);
    newConfig.interfaces[0].routes = [
      ...newConfig.interfaces[0].routes,
      { gateway: value, network: '0.0.0.0/0' },
    ];
    onChange(newConfig, index);
  }

  function handleCreateNameserverOption(inputValue: string) {
    const value = inputValue.trim();
    // Prevent a space from being added as a new option
    if (!value) return;

    setNewNameserverOptions([...newNameserverOptions, { label: value, value }]);

    const newConfig = structuredClone(machineFormValues);
    newConfig.nameservers = [...newConfig.nameservers, value];
    onChange(newConfig, index);
  }
}
