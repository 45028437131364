import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';

import { keyBuilder } from '@/react/hooks/useLocalStorage';
import { EndpointAuthorizations } from '@/portainer/users/types';
import { CurrentUserResponse } from '@/portainer/users/queries/useLoadCurrentUser';

type State = {
  user?: CurrentUserResponse;
  authorizations: EndpointAuthorizations;
};

type Functions = {
  clear(): void;
};

export const currentUserStore = createStore<State & Functions>()(
  persist(
    (set) => ({
      user: undefined,
      authorizations: {},
      clear: () => set({ user: undefined, authorizations: undefined }),
    }),
    {
      name: keyBuilder('current_user'),
      getStorage: () => localStorage,
    }
  )
);
