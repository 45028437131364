import Omni from '@/assets/ico/vendor/omni-icon.svg?c';
import { BetaAlert } from '@/react/portainer/environments/update-schedules/common/BetaAlert';

import { Widget, WidgetTitle } from '@@/Widget';

import { UpdateK8sVersionForm } from './UpdateK8sVersionForm';
import { UpdateTalosVersionForm } from './UpdateTalosVersionForm';
import { OmniUpgradeStatus } from './OmniUpgradeStatus';
import {
  k8sUpgradePhaseToAlertProps,
  talosUpgradePhaseToAlertProps,
} from './constants';

export function OmniUpdateWidget() {
  return (
    <div className="row">
      <div className="col-sm-12">
        <Widget>
          <WidgetTitle icon={Omni} title="Omni cluster management" />
          <div className="flex flex-col gap-y-5 p-5">
            <BetaAlert message="Beta feature - so far, Omni cluster management functionality has only been tested in a limited set of scenarios." />
            <OmniUpgradeStatus
              statusType="k8s"
              phaseAlertMap={k8sUpgradePhaseToAlertProps}
            />
            <UpdateK8sVersionForm />
            <OmniUpgradeStatus
              statusType="talos"
              phaseAlertMap={talosUpgradePhaseToAlertProps}
            />
            <UpdateTalosVersionForm />
          </div>
        </Widget>
      </div>
    </div>
  );
}
