import { Node, Endpoints } from 'kubernetes-types/core/v1';
import { HardDrive, RefreshCw } from 'lucide-react';
import { useMemo } from 'react';
import { useRouter } from '@uirouter/react';
import { compact } from 'lodash';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { createStore } from '@/react/kubernetes/datatables/default-kube-datatable-store';
import {
  Authorized,
  useAuthorizations,
  useCurrentUser,
} from '@/react/hooks/useUser';
import { IndexOptional } from '@/react/kubernetes/configs/types';
import { useEnvironment } from '@/react/portainer/environments/queries';
import { pluralize } from '@/portainer/helpers/strings';
import { notifySuccess } from '@/portainer/services/notifications';
import { useCloudCredential } from '@/react/portainer/settings/sharedCredentials/cloudSettings.service';
import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';

import { confirm } from '@@/modals/confirm';
import { Datatable, TableSettingsMenu } from '@@/datatables';
import { useTableState } from '@@/datatables/useTableState';
import { AddButton, ButtonGroup, LoadingButton } from '@@/buttons';
import { TextTip } from '@@/Tip/TextTip';
import { TableSettingsMenuAutoRefresh } from '@@/datatables/TableSettingsMenuAutoRefresh';
import { DeleteButton } from '@@/buttons/DeleteButton';
import { ModalType } from '@@/modals';

import { useNodesQuery } from '../nodes.service';
import { useKubernetesEndpointsQuery } from '../../kubernetesEndpoint.service';
import { useOmniCluster } from '../../omni/queries/useOmniCluster';
import { useRebootOmniNode } from '../../omni/queries/useRebootOmniNode';
import { OmniClusterPhase } from '../../omni/types';
import { useUpdateCluster } from '../../omni/queries/useUpdateCluster';
import { UpdateOmniClusterPayload } from '../omni/types';

import { getColumns } from './columns';
import { OmniNodeRowData } from './types';
import { getInternalNodeIpAddress, getRole } from './utils';

const storageKey = 'k8sNodesDatatable';
const settingsStore = createStore(storageKey);

// refactor to use the base nodes datatable with all cluster types
export function OmniNodesDatatable() {
  const tableState = useTableState(settingsStore, storageKey);
  const environmentId = useEnvironmentId();
  const nodesQuery = useNodesQuery(environmentId, {
    autoRefreshRate: tableState.autoRefreshRate * 1000,
  });
  const nodes = nodesQuery.data;
  const { data: kubernetesEndpoints, ...kubernetesEndpointsQuery } =
    useKubernetesEndpointsQuery(environmentId, {
      autoRefreshRate: tableState.autoRefreshRate * 1000,
    });
  const environmentQuery = useEnvironment(environmentId);
  const environment = environmentQuery.data;
  const environmentUrl = environment?.URL;
  const isServerMetricsEnabled =
    !!environment?.Kubernetes?.Configuration.UseServerMetrics;
  const credentialId = environment?.CloudProvider?.CredentialID;

  const nodeRowData = useNodeRowData(
    nodes,
    kubernetesEndpoints,
    environmentUrl
  );
  const { isPureAdmin } = useCurrentUser();
  const { data: credential, ...credentialQuery } = useCloudCredential(
    credentialId ?? NaN,
    isPureAdmin // if the user is admin
  );
  const controlPlaneNodeCount = nodeRowData?.filter(
    (node) => node.role === 'Control plane'
  ).length;

  const authorizedToWriteClusterNodeQuery =
    useAuthorizations('K8sClusterNodeW');
  const authorizedToWriteClusterNode =
    authorizedToWriteClusterNodeQuery.authorized;
  if (authorizedToWriteClusterNodeQuery.isLoading) {
    return null;
  }

  return (
    <Datatable<IndexOptional<OmniNodeRowData>>
      dataset={nodeRowData ?? []}
      columns={getColumns(isServerMetricsEnabled)}
      settingsManager={tableState}
      isLoading={
        nodesQuery.isLoading ||
        kubernetesEndpointsQuery.isLoading ||
        environmentQuery.isLoading
      }
      title="Nodes"
      titleIcon={HardDrive}
      getRowId={(row) => row.metadata?.uid ?? ''}
      isRowSelectable={(row) => !row.original.isPublishedNode}
      disableSelect={!authorizedToWriteClusterNode}
      renderTableActions={(selectedRows) =>
        authorizedToWriteClusterNode && (
          <TableActions
            selectedItems={selectedRows}
            controlPlaneNodeCount={controlPlaneNodeCount}
          />
        )
      }
      renderTableSettings={() => (
        <TableSettingsMenu>
          <TableSettingsMenuAutoRefresh
            value={tableState.autoRefreshRate}
            onChange={(value) => tableState.setAutoRefreshRate(value)}
          />
        </TableSettingsMenu>
      )}
      description={
        authorizedToWriteClusterNode &&
        !credential &&
        credentialQuery.isFetched && (
          <div className="w-full">
            <TextTip color="orange">
              No credentials found for the Omni cluster.
            </TextTip>
          </div>
        )
      }
      data-cy="k8s-omni-nodes-datatable"
    />
  );
}

function TableActions({
  selectedItems,
  controlPlaneNodeCount,
}: {
  selectedItems: IndexOptional<OmniNodeRowData>[];
  controlPlaneNodeCount: number;
}) {
  const router = useRouter();

  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const clusterQuery = useOmniCluster(environment?.Name ?? '', credentialId, {
    enabled: !!environment?.Name,
  });
  const isProcessing =
    clusterQuery.data?.status.phase !== OmniClusterPhase.RUNNING;
  const selectedControlPlaneNodeCount = selectedItems.filter(
    (item) => item.role === 'Control plane'
  ).length;
  const areAllControlPlaneNodesSelected =
    selectedControlPlaneNodeCount === controlPlaneNodeCount;
  const isRemoveDisabled =
    selectedItems.length === 0 ||
    isProcessing ||
    areAllControlPlaneNodesSelected;

  const removeNodesMutation = useUpdateCluster(credentialId);
  const useRebootOmniNodeMutation = useRebootOmniNode(
    environment?.Name ?? '',
    credentialId
  );
  return (
    <Authorized authorizations="K8sClusterNodeW">
      <ButtonGroup>
        <LoadingButton
          color="default"
          icon={RefreshCw}
          isLoading={useRebootOmniNodeMutation.isLoading}
          loadingText="Rebooting..."
          disabled={selectedItems.length === 0 || isProcessing}
          onClick={() => onRebootClick()}
          data-cy="k8sNodes-rebootNodeButton"
        >
          Reboot
        </LoadingButton>
        <DeleteButton
          disabled={isRemoveDisabled}
          onConfirmed={() => onRemoveClick(selectedItems)}
          data-cy="k8sNodes-removeNodeButton"
          confirmMessage={`Removing a node uninstalls Omni from it. During this time, the cluster may become unreachable. Are you sure you want to remove the selected ${pluralize(
            selectedItems.length,
            'node'
          )}?`}
        />
      </ButtonGroup>
      <AddButton
        color="secondary"
        to="kubernetes.cluster.nodes.new"
        disabled={isProcessing}
        data-cy="k8sNodes-addNodesButton"
      >
        Add nodes
      </AddButton>
    </Authorized>
  );

  async function onRemoveClick(
    selectedNodes: IndexOptional<OmniNodeRowData>[]
  ) {
    const controlPlanesToRemove = compact(
      selectedNodes
        .filter((node) => node.role === 'Control plane')
        .map((node) => node.metadata?.name ?? '')
    );
    const workersToRemove = compact(
      selectedNodes
        .filter((node) => node.role === 'Worker')
        .map((node) => node.metadata?.name ?? '')
    );
    const nodesToDeletePayload: UpdateOmniClusterPayload = {
      cluster: {
        kind: 'Cluster',
        name: environment?.Name ?? '',
      },
      controlPlanesToRemove,
      workersToRemove,
    };
    removeNodesMutation.mutate(nodesToDeletePayload, {
      onSuccess: () => {
        notifySuccess(
          'Success',
          'Request to remove nodes successfully submitted.'
        );
        router.stateService.reload();
      },
    });
  }

  async function onRebootClick() {
    const confirmed = await confirm({
      title: 'Are you sure?',
      message: `Are you sure you want to reboot the ${pluralize(
        selectedItems.length,
        'node'
      )}? This may cause the cluster to be unavailable while rebooting.`,
      cancelButtonLabel: 'Cancel',
      modalType: ModalType.Warn,
    });
    if (confirmed) {
      useRebootOmniNodeMutation.mutate(
        compact(selectedItems.map((item) => item.metadata?.name)),
        {
          onSuccess: () => router.stateService.reload(),
        }
      );
    }
  }
}

/**
 * This function is used to add the isApi property to the node row data.
 */
function useNodeRowData(
  nodes?: Node[],
  kubernetesEndpoints?: Endpoints[],
  environmentUrl?: string
): OmniNodeRowData[] {
  return useMemo<OmniNodeRowData[]>(() => {
    if (!nodes || !kubernetesEndpoints) {
      return [];
    }
    const subsetAddresses = kubernetesEndpoints?.flatMap(
      (endpoint) =>
        endpoint.subsets?.flatMap((subset) => subset.addresses ?? [])
    );
    const nodeRowData = nodes.map((node) => {
      const nodeAddress = getInternalNodeIpAddress(node);
      // if the node address is in the endpoints subset addresses, then it is an api node
      const isApi = subsetAddresses?.some(
        (subsetAddress) => subsetAddress?.ip === nodeAddress
      );
      // if the environment url includes the node address, then it is a published node
      const isPublishedNode =
        !!nodeAddress &&
        !!environmentUrl &&
        environmentUrl?.includes(nodeAddress);
      const nodeName = node.metadata?.name ?? '';
      return {
        ...node,
        isApi,
        isPublishedNode,
        role: getRole(node),
        Name: `${nodeName}${isApi ? 'api' : ''}`,
      };
    });
    return nodeRowData;
  }, [nodes, kubernetesEndpoints, environmentUrl]);
}
