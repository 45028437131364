import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { SchemaOf } from 'yup';

import { notifySuccess } from '@/portainer/services/notifications';
import { useUpdateDebugSettingsMutation } from '@/react/portainer/settings/queries';

import { LoadingButton } from '@@/buttons/LoadingButton';

import { useDebugSettings } from '../../queries/useDebugSettings';

import { EnableDebugLogSwitch } from './EnableDebugLogSwitch';

interface FormValues {
  debugLogEnabled: boolean;
}

const validation: SchemaOf<FormValues> = yup.object({
  debugLogEnabled: yup.boolean().required('Debug log is required'),
});

export function DebugLogForm() {
  const debugSettingsQuery = useDebugSettings();
  const initialValues: FormValues = {
    debugLogEnabled: debugSettingsQuery.data?.debugLogEnabled ?? false,
  };

  const updateDebugSettingsMutation = useUpdateDebugSettingsMutation();

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validation}
      validateOnMount
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form className="form-horizontal">
          <EnableDebugLogSwitch isLoading={debugSettingsQuery.isLoading} />

          <div className="form-group">
            <div className="col-sm-12">
              <LoadingButton
                loadingText="Saving settings..."
                isLoading={updateDebugSettingsMutation.isLoading}
                disabled={!isValid || !dirty}
                className="!ml-0"
                data-cy="settings-debugLogButton"
              >
                Save debug settings
              </LoadingButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  function handleSubmit(values: FormValues) {
    updateDebugSettingsMutation.mutate(values, {
      onSuccess() {
        notifySuccess('Success', 'Successfully updated debug log settings');
      },
    });
  }
}
