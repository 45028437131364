import { Formik, Form, useFormikContext } from 'formik';

import { notifySuccess } from '@/portainer/services/notifications';
import { useAuthorizations } from '@/react/hooks/useUser';
import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { semverCompare } from '@/react/common/semver-utils';
import { useSetAvailableOption } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardKaaS/useSetAvailableOption';

import { confirm } from '@@/modals/confirm';
import { Card } from '@@/Card';
import { FormControl } from '@@/form-components/FormControl';
import { LoadingButton } from '@@/buttons';
import { ModalType } from '@@/modals';
import { PortainerSelect } from '@@/form-components/PortainerSelect';
import { Alert } from '@@/Alert';
import { TextTip } from '@@/Tip/TextTip';

import { useOmniCluster } from '../../omni/queries/useOmniCluster';
import { useUpdateCluster } from '../../omni/queries/useUpdateCluster';
import { OmniClusterUpgradePhase } from '../../omni/types';
import { useOmniClusterUpgradeStatus } from '../../omni/queries/useOmniClusterStatus';

import { UpdateOmniClusterPayload } from './types';

export type K8sUpdateFormValues = {
  kubeVersion: string;
};

export function UpdateK8sVersionForm() {
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const clusterQuery = useOmniCluster(environment?.Name ?? '', credentialId, {
    enabled: !!environment?.Name,
  });
  const kubeVersion = clusterQuery.data?.metadata.kubernetes_version
    ? `v${clusterQuery.data?.metadata.kubernetes_version}`
    : '';
  const initialValues: K8sUpdateFormValues = {
    kubeVersion,
  };
  const upgradeClusterMutation = useUpdateCluster(credentialId);

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpgradeCluster}
        validateOnMount
        enableReinitialize
      >
        <UpdateClusterInnerForm />
      </Formik>
    </Card>
  );

  async function handleUpgradeCluster(values: K8sUpdateFormValues) {
    if (!environment) return;
    const confirmed = await confirm({
      title: 'Are you sure?',
      message:
        'Are you sure you want to upgrade the cluster? This may cause the cluster to be unavailable during the upgrade process.',
      cancelButtonLabel: 'Cancel',
      modalType: ModalType.Warn,
    });
    if (confirmed) {
      const payload: UpdateOmniClusterPayload = {
        cluster: {
          kind: 'Cluster',
          name: environment.Name,
          kubernetes: {
            version: values.kubeVersion,
          },
        },
      };

      await upgradeClusterMutation.mutateAsync(payload, {
        onSuccess: () => {
          notifySuccess('Success', 'Cluster Kubernetes upgrade started');
        },
      });
    }
  }
}

function UpdateClusterInnerForm() {
  const { isSubmitting, values, setFieldValue } =
    useFormikContext<K8sUpdateFormValues>();
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const environmentName = environment?.Name;
  const clusterQuery = useOmniCluster(environmentName ?? '', credentialId, {
    enabled: !!environmentName,
  });
  const k8sVersion = clusterQuery.data?.metadata.kubernetes_version
    ? `v${clusterQuery.data?.metadata.kubernetes_version}`
    : '';

  const clusterStatusQuery = useOmniClusterUpgradeStatus(
    'k8s',
    environmentQuery.data?.Name ?? '',
    credentialId
  );
  const k8sCurrentVersionOption = {
    value: k8sVersion,
    label: `${k8sVersion} (current)`,
  };
  const k8sUpgradeVersionOptions =
    clusterStatusQuery.data?.upgrade_versions?.map((version) => ({
      label: `v${version}`,
      value: `v${version}`,
    }));
  const k8sVersionOptions = [
    k8sCurrentVersionOption,
    ...(k8sUpgradeVersionOptions ?? []),
  ].sort((a, b) => semverCompare(a.value, b.value));
  const phase = clusterStatusQuery.data?.phase;

  const isNewVersionAvailable = k8sVersion
    ? k8sVersionOptions?.some(
        (version) => semverCompare(version.value, k8sVersion) > 0
      ) ?? false
    : false;
  useSetAvailableOption(k8sVersionOptions, values.kubeVersion, 'kubeVersion');

  const { authorized: isAllowedToUpdateKubeVersion } = useAuthorizations([
    'K8sClusterW',
  ]);

  if (clusterStatusQuery.isError) {
    return <Alert color="error">Unable to get Kubernetes versions</Alert>;
  }

  if (environmentQuery.isError) {
    return <Alert color="error">Unable to load environment</Alert>;
  }

  return (
    <Form className="form-horizontal">
      <FormControl
        label="Kubernetes version"
        tooltip="Kubernetes version running on the cluster."
        inputId="kubeVersion"
        isLoading={clusterStatusQuery.isInitialLoading}
        loadingText="Loading Kubernetes versions..."
      >
        <PortainerSelect
          options={k8sVersionOptions}
          value={values.kubeVersion}
          onChange={(selectedVersion: string) =>
            setFieldValue('kubeVersion', selectedVersion)
          }
          data-cy="omniKubeVersionSelect"
          placeholder="Kubernetes version"
          disabled={!isAllowedToUpdateKubeVersion}
        />
        {isNewVersionAvailable && (
          <TextTip color="blue">New Kubernetes version(s) available!</TextTip>
        )}
      </FormControl>
      {isAllowedToUpdateKubeVersion && (
        <LoadingButton
          isLoading={isSubmitting}
          data-cy="upgrade-cluster-button"
          loadingText="Upgrading..."
          type="submit"
          color="secondary"
          className="!ml-0"
          onClick={() => {}}
          disabled={
            values.kubeVersion === k8sVersion ||
            isSubmitting ||
            phase !== OmniClusterUpgradePhase.DONE
          }
        >
          Update Kubernetes version
        </LoadingButton>
      )}
    </Form>
  );
}
