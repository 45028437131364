import { Form, FormikErrors, useFormikContext } from 'formik';
import { kebabCase } from 'lodash';
import { Plus } from 'lucide-react';

import { MachineSetNodeSelect } from '@/react/kubernetes/cluster/omni/components/MachineSetNodeSelect';

import { FormControl } from '@@/form-components/FormControl';
import { FormSection } from '@@/form-components/FormSection';
import { FormActions } from '@@/form-components/FormActions';
import { isArrayErrorType } from '@@/form-components/formikUtils';

import { OmniClusterMachineSet, OmniMachineFormValues } from '../../omni/types';
import { OmniAddNodesFormValues } from '../types';

export function OmniAddNodesInnerForm({
  credentialId,
  talosVersion,
  machineOptions,
  isLoadingMachines,
}: {
  credentialId?: number;
  talosVersion?: string;
  machineOptions: Array<{ label: string; value: string }>;
  isLoadingMachines: boolean;
}) {
  const { values, setFieldValue, isSubmitting, errors, isValid } =
    useFormikContext<OmniAddNodesFormValues>();
  const machineSetErrors = getMachinesErrors(errors?.machineSets);
  const selectedMachineCount = values.machineSets.reduce(
    (acc, machineSet) => acc + machineSet.machines.length,
    0
  );

  if (!credentialId) {
    return null;
  }

  return (
    <Form className="form-horizontal">
      <FormSection title="Add nodes to cluster">
        {values.machineSets.map((machineSet, index) => (
          <MachineSetFormItem
            key={machineSet.name}
            machineSet={machineSet}
            index={index}
            talosVersion={talosVersion}
            machineOptions={machineOptions}
            isLoadingMachines={isLoadingMachines}
            machineSetErrors={machineSetErrors}
            credentialId={credentialId}
            setFieldValue={setFieldValue}
            machineSets={values.machineSets}
          />
        ))}
      </FormSection>
      <FormActions
        submitLabel="Add nodes"
        loadingText="Adding nodes..."
        submitIcon={Plus}
        isLoading={isSubmitting}
        isValid={selectedMachineCount > 0 && isValid}
        data-cy="add-omni-nodes-button"
      />
    </Form>
  );
}

type MachineSetFormItemProps = {
  machineSet: OmniClusterMachineSet;
  index: number;
  talosVersion?: string;
  machineOptions: Array<{ label: string; value: string }>;
  isLoadingMachines: boolean;
  machineSetErrors?: (FormikErrors<OmniMachineFormValues>[] | undefined)[];
  credentialId?: number;
  setFieldValue: (field: string, value: unknown) => void;
  machineSets: OmniClusterMachineSet[];
};

function MachineSetFormItem({
  machineSet,
  index,
  talosVersion,
  machineOptions,
  isLoadingMachines,
  machineSetErrors,
  credentialId,
  setFieldValue,
  machineSets,
}: MachineSetFormItemProps) {
  const inputId = kebabCase(machineSet.name);

  return (
    <FormControl
      key={machineSet.name}
      label={machineSet.name}
      inputId={inputId}
      tooltip={
        machineSet.role === 'control-plane'
          ? 'Control plane nodes manage cluster state and workload scheduling on worker nodes. For high availability, use 3 nodes (or 5 for greater reliability).'
          : undefined
      }
      isLoading={isLoadingMachines}
      loadingText="Loading machine options..."
      errors={machineSetErrors?.[index]?.[0]?.talosVersion}
    >
      {!!talosVersion && credentialId !== undefined && (
        <MachineSetNodeSelect
          talosVersion={talosVersion}
          values={machineSet.machines}
          errors={machineSetErrors?.[index]}
          onChange={(machines: OmniMachineFormValues[]) => {
            setFieldValue(`machineSets[${index}].machines`, machines);
          }}
          options={machineOptions}
          machineSetRole={machineSet.role}
          machineSetValues={machineSets}
          data-cy={`microk8sCreateForm-workerNodesInput${index}`}
          credentialId={credentialId}
          inputId={inputId}
        />
      )}
    </FormControl>
  );
}

// getMachinesErrors filters the errors type so it can be passed as a clean prop to MachineSetNodeSelect
function getMachinesErrors(
  machineSetsErrors?: string | string[] | FormikErrors<OmniClusterMachineSet>[]
) {
  const machineErrors = isArrayErrorType(machineSetsErrors)
    ? machineSetsErrors
    : undefined;
  if (!machineErrors) {
    return undefined;
  }
  const machinesErrors = machineErrors.map((machineSetErrors) =>
    isArrayErrorType(machineSetErrors?.machines)
      ? machineSetErrors.machines
      : undefined
  );
  return machinesErrors;
}
