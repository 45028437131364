import { useQuery } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';

import { SupportDebugSettings } from '../types';

import { queryKeys } from './queryKeys';

export function useDebugSettings<T = SupportDebugSettings>(
  select?: (settings: SupportDebugSettings) => T,
  enabled = true
) {
  return useQuery(queryKeys.debug(), getDebugSettings, {
    select,
    enabled,
    ...withGlobalError('Unable to retrieve debug settings'),
  });
}

async function getDebugSettings() {
  try {
    const { data } =
      await axios.get<SupportDebugSettings>('/support/debug_log');
    return data;
  } catch (e) {
    throw parseAxiosError(e, 'Unable to retrieve debug settings');
  }
}
