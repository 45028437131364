// UpdateOmniClusterPayload

import { array, object, SchemaOf } from 'yup';

import { validateMachineSet } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/useOmniValidation';

import { OmniAddNodesFormValues } from '../types';

export function omniAddNodesFormValidation(
  talosVersion?: string
): SchemaOf<OmniAddNodesFormValues> {
  return object({
    machineSets: array(validateMachineSet(talosVersion)),
  });
}
