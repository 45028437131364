import _ from 'lodash';

import axios from '@/portainer/services/axios';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { urlBuilder } from '@/react/kubernetes/applications/axios/pods/urlBuilder';
import { parseKubernetesAxiosError } from '@/react/kubernetes/axiosError';

export type PodLogsParams = {
  timestamps?: boolean;
  sinceSeconds?: number;
  tailLines?: number;
  container?: string;
};

type PodLogsResponse = {
  logs: string;
};

export async function getPodLogs(
  environmentId: EnvironmentId,
  namespace: string,
  podId: string,
  params?: PodLogsParams
): Promise<string> {
  try {
    const { data } = await axios.get<PodLogsResponse>(
      urlBuilder(environmentId, namespace, podId, 'log'),
      {
        params: _.pickBy(params),
        // transforming in this way allows plain numbers to be expressed in full, as a string. Simply applying JSON.stringify would lose this information.
        // see https://github.com/portainer/portainer-suite/blob/2ae1b5c2391547f3727ae57d9c74a1064c6175ae/package/server-ce/app/kubernetes/rest/pod.js#L42 for the angular implementation
        transformResponse: (data) => ({
          logs: data,
        }),
      }
    );
    return data.logs;
  } catch (e) {
    throw parseKubernetesAxiosError(e, 'Unable to get pod logs');
  }
}
