import { ComponentProps } from 'react';

import { Alert } from '@@/Alert';

import { OmniClusterUpgradePhase } from '../../omni/types';

export const k8sUpgradePhaseToAlertProps: Record<
  OmniClusterUpgradePhase,
  ComponentProps<typeof Alert>
> = {
  [OmniClusterUpgradePhase.DONE]: {
    color: 'success',
    title: 'Cluster has completed the Kubernetes version upgrade',
  },
  [OmniClusterUpgradePhase.FAILED]: {
    color: 'error',
    title: 'Cluster failed to complete latest Kubernetes upgrade',
  },
  [OmniClusterUpgradePhase.REVERTING]: {
    color: 'info',
    title: 'Cluster is reverting changes',
  },
  [OmniClusterUpgradePhase.UNKNOWN]: {
    color: 'error',
    title: 'Cluster status is unknown',
  },
  [OmniClusterUpgradePhase.UPGRADING]: {
    color: 'info',
    title: 'Updating Kubernetes version',
  },
};

export const talosUpgradePhaseToAlertProps: Record<
  OmniClusterUpgradePhase,
  ComponentProps<typeof Alert>
> = {
  [OmniClusterUpgradePhase.DONE]: {
    color: 'success',
    title: 'Cluster has completed the Talos version upgrade',
  },
  [OmniClusterUpgradePhase.FAILED]: {
    color: 'error',
    title: 'Cluster failed to complete latest Talos version upgrade',
  },
  [OmniClusterUpgradePhase.REVERTING]: {
    color: 'info',
    title: 'Cluster is reverting changes',
  },
  [OmniClusterUpgradePhase.UNKNOWN]: {
    color: 'error',
    title: 'Cluster status is unknown',
  },
  [OmniClusterUpgradePhase.UPGRADING]: {
    color: 'info',
    title: 'Updating Talos version',
  },
};
