import { useQuery } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { AuthorizationMap } from '@/react/portainer/users/RolesView/types';

import { User } from '../types';

import { userQueryKeys } from './queryKeys';

export interface CurrentUserResponse extends User {
  forceChangePassword?: boolean;
}

export function useLoadCurrentUser({ staleTime }: { staleTime?: number } = {}) {
  return useQuery(userQueryKeys.me(), () => getCurrentUser(), {
    ...withGlobalError('Unable to retrieve user details'),
    staleTime,
  });
}

export async function getCurrentUser() {
  try {
    const { data: user } = await axios.get<CurrentUserResponse>('/users/me', {
      params: { noEndpointAuthorizations: true },
    });

    return user;
  } catch (e) {
    throw parseAxiosError(e as Error, 'Unable to retrieve user details');
  }
}

export function useUserEnvironmentAuthorizations({
  environmentId,
  enabled,
}: {
  environmentId?: EnvironmentId;
  enabled?: boolean;
}) {
  return useQuery(
    userQueryKeys.authorizations(environmentId!),
    () => getUserEnvironmentAuthorizations(environmentId!),
    {
      enabled: !!environmentId && !!enabled,
      ...withGlobalError(),
    }
  );
}

type EnvironmentAuthorizations = {
  Authorizations: AuthorizationMap;
};

export async function getUserEnvironmentAuthorizations(
  environmentId?: EnvironmentId
) {
  if (!environmentId) {
    return {};
  }
  try {
    const { data } = await axios.get<EnvironmentAuthorizations>(
      `/users/me/auth/${environmentId}`
    );

    return { [environmentId]: data.Authorizations };
  } catch (e) {
    throw parseAxiosError(
      e,
      `Unable to retrieve user authorizations for environment ${environmentId}`
    );
  }
}
