import { hasAuthorizations as useUserHasAuthorization } from '@/react/hooks/useUser';
import { currentUserStore } from '@/react/hooks/current-user-store';
import { getCurrentUser, getUserEnvironmentAuthorizations } from '../users/queries/useLoadCurrentUser';
import * as userHelpers from '../users/user.helpers';
angular.module('portainer.app').factory(
  'Authentication',

  /* @ngInject */
  function AuthenticationFactory($async, $state, Auth, OAuth, LocalStorage, StateManager, EndpointProvider, EndpointService, ThemeManager) {
    let user = {};

    if (process.env.NODE_ENV === 'development') {
      window.login = loginAsync;
    }

    // sync the AJS store when the React store updates
    currentUserStore.subscribe(({ user: userData, authorizations }) => {
      // don't override the user object with user = { ... } because there is a possibility
      // that some code in AJS is adding hooks/watchers on the original object (object ref shared with getUserDetails() )
      // and we would void them...
      if (userData) {
        saveUserData(userData, authorizations);
      }
    });

    return {
      init: () => $async(initAsync),
      OAuthLogin: (code) => $async(OAuthLoginAsync, code),
      login: (username, password) => $async(loginAsync, username, password),
      logout: () => $async(logoutAsync),
      isAuthenticated,
      getUserDetails,
      isAdmin,
      isEdgeAdmin,
      isPureAdmin,
      hasAuthorizations,
      redirectIfUnauthorized,
    };

    async function initAsync() {
      try {
        const userId = LocalStorage.getUserId();
        if (userId && user.ID === userId) {
          return true;
        }
        await loadUserData();
        return true;
      } catch (error) {
        return false;
      }
    }

    async function logoutAsync() {
      if (isAuthenticated()) {
        await Auth.logout().$promise;
      }

      sessionStorage.clear();
      StateManager.clean();
      EndpointProvider.clean();
      LocalStorage.cleanAuthData();
      LocalStorage.storeLoginStateUUID('');
      cleanUserData();
    }

    function cleanUserData() {
      user = {};
      currentUserStore.getState().clear();
    }

    async function OAuthLoginAsync(code) {
      await OAuth.validate({ code: code }).$promise;
      await loadUserData();
    }

    async function loginAsync(username, password) {
      await Auth.login({ username: username, password: password }).$promise;
      await loadUserData();
    }

    function isAuthenticated() {
      return !!user.ID;
    }

    function getUserDetails() {
      return user;
    }

    function saveUserData(userData, authorizations = {}) {
      user.ID = userData.Id;
      user.username = userData.Username;
      user.role = userData.Role;
      user.forceChangePassword = userData.forceChangePassword;
      user.endpointAuthorizations = authorizations;
      user.portainerAuthorizations = userData.PortainerAuthorizations;
    }

    async function loadUserData() {
      const userData = await getCurrentUser();
      const endpointId = EndpointProvider.endpointID();
      let currentEndpoint = EndpointProvider.currentEndpoint();

      // If we have an endpointId but we don't have endpoint data, we need to fetch the endpoint data
      if (endpointId && (!currentEndpoint || currentEndpoint.Id !== endpointId)) {
        currentEndpoint = await EndpointService.endpoint(endpointId);
        EndpointProvider.setCurrentEndpoint(currentEndpoint);
      }

      // Get authorizations only if we're not an admin
      if (endpointId && !userHelpers.isEdgeAdmin(userData, currentEndpoint)) {
        const authData = await getUserEnvironmentAuthorizations(endpointId);
        saveUserData(userData, authData);
      } else {
        saveUserData(userData);
      }

      // Initialize user theme base on UserTheme from database
      const userTheme = userData.ThemeSettings ? userData.ThemeSettings.color : 'auto';
      if (userTheme === 'auto' || !userTheme) {
        ThemeManager.autoTheme();
      } else {
        ThemeManager.setTheme(userTheme);
      }

      LocalStorage.storeUserId(userData.Id);
    }

    // To avoid creating divergence between CE and EE
    // isAdmin checks if the user is a portainer admin or edge admin
    function isEdgeAdmin(noEnvScope = false) {
      const environment = EndpointProvider.currentEndpoint();
      return userHelpers.isEdgeAdmin({ Role: user.role }, noEnvScope ? undefined : environment);
    }

    /**
     * @deprecated use Authentication.isAdmin instead
     */
    function isAdmin(noEnvScope = false) {
      return isEdgeAdmin(noEnvScope);
    }

    // To avoid creating divergence between CE and EE
    // isPureAdmin checks if the user is portainer admin only
    function isPureAdmin() {
      return userHelpers.isPureAdmin({ Role: user.role });
    }

    /**
     * Checks if the user has the required authorizations for this environment
     *
     * @param {Array<string>} authorizations
     * @returns boolean
     */
    function hasAuthorizations(authorizations) {
      const endpointId = EndpointProvider.endpointID();

      if (isEdgeAdmin()) {
        return true;
      }

      return useUserHasAuthorization(
        {
          Role: user.role,
          EndpointAuthorizations: user.endpointAuthorizations,
        },
        authorizations,
        endpointId
      );
    }

    function redirectIfUnauthorized(authorizations) {
      const authorized = hasAuthorizations(authorizations);
      if (!authorized) {
        $state.go('portainer.home');
      }
    }
  }
);
