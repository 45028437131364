import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { useEnvironment } from '@/react/portainer/environments/queries';

import { PageHeader } from '@@/PageHeader';
import { Widget, WidgetBody } from '@@/Widget';

import { MicroK8sAddNodesForm } from './microk8s/MicroK8sAddNodesForm';
import { OmniAddNodesForm } from './omni/OmniAddNodesForm';

export function NodeCreateView() {
  const environmentId = useEnvironmentId();
  const clusterProviderQuery = useEnvironment(environmentId, (env) => {
    const isMicroK8s =
      env?.CloudProvider?.Provider?.toLowerCase() === 'microk8s';
    const isOmniK8s = env?.CloudProvider?.Provider?.toLowerCase() === 'omni';
    return { isMicroK8s, isOmniK8s };
  });
  const { isMicroK8s, isOmniK8s } = clusterProviderQuery.data ?? {};
  return (
    <>
      <PageHeader
        title="Create nodes"
        breadcrumbs={[
          { label: 'Cluster information', link: 'kubernetes.cluster' },
          { label: 'Create nodes' },
        ]}
        reload
      />
      <div className="row">
        <div className="col-sm-12">
          <Widget>
            <WidgetBody>
              {isOmniK8s && <OmniAddNodesForm />}
              {isMicroK8s && <MicroK8sAddNodesForm />}
            </WidgetBody>
          </Widget>
        </div>
      </div>
    </>
  );
}
