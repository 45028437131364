import { useRouter } from '@uirouter/react';

import { FormSectionTitle } from '@@/form-components/FormSectionTitle';
import { TextTip } from '@@/Tip/TextTip';
import { useDocsUrl } from '@@/PageHeader/ContextHelp';

import {
  useCreateCredentialMutation,
  useCloudCredentials,
} from '../cloudSettings.service';
import {
  CreateCredentialPayload,
  GenericFormValues,
  credentialTitles,
  credentialTypeHelpLinks,
  CredentialType,
} from '../types';
import { APICredentialsForm } from '../components/APICredentialsForm';
import { GCPCredentialsForm } from '../components/GCPCredentialsForm';
import { AWSCredentialsForm } from '../components/AWSCredentialsForm';
import { AzureCredentialsForm } from '../components/AzureCredentialsForm';
import { SSHCredentialsForm } from '../components/SSHCredentialsForm';
import { trimObject } from '../utils';
import { OmniCredentialsForm } from '../components/OmniCredentialsForm';

type Props = {
  credentialType: CredentialType;
  routeOnSuccess?: string;
};

export function CredentialsForm({ credentialType, routeOnSuccess }: Props) {
  const router = useRouter();
  const Form = getForm(credentialType);

  const createCredentialMutation = useCreateCredentialMutation();
  const cloudCredentialsQuery = useCloudCredentials();
  const credentialNames =
    cloudCredentialsQuery.data
      ?.filter((c) => c.provider === credentialType)
      .map((c: CreateCredentialPayload) => c.name) || [];

  return (
    <div className="flex flex-col">
      <FormSectionTitle>Credential details</FormSectionTitle>
      <CredentialInfo credentialType={credentialType} />
      <Form
        selectedProvider={credentialType}
        isLoading={createCredentialMutation.isLoading}
        onSubmit={onSubmit}
        credentialNames={credentialNames}
      />
    </div>
  );

  function onSubmit(values: GenericFormValues) {
    const payload: CreateCredentialPayload = {
      provider: credentialType,
      name: values.name.trim(),
      credentials: trimObject(values.credentials),
    };
    // base64 encode the private key
    if (
      'privateKey' in payload.credentials &&
      typeof payload.credentials.privateKey === 'string'
    ) {
      payload.credentials.privateKey = window.btoa(
        payload.credentials.privateKey
      );
    }
    createCredentialMutation.mutate(payload, {
      onSuccess: () => {
        if (routeOnSuccess) {
          router.stateService.go(routeOnSuccess);
        }
      },
    });
  }
}

function CredentialInfo({
  credentialType,
}: {
  credentialType: CredentialType;
}) {
  const title = credentialTitles[credentialType];
  const helpLink = useDocsUrl(credentialTypeHelpLinks[credentialType]);

  if (credentialType === CredentialType.OMNI) {
    return (
      <TextTip color="blue" className="mb-2">
        <p>
          You will need to create an Omni Service Account with an{' '}
          <b>admin role</b>. You can create one through the Sidero Omni UI or
          using omnictl. See our{' '}
          <a
            className="hyperlink"
            href={helpLink}
            target="_blank"
            rel="noreferrer"
          >
            documentation on creating {title} credentials
          </a>{' '}
          for more information.
        </p>
        <p>
          Any credentials that you set up will be usable by all admin users
          (although the actual values themselves cannot be viewed).
        </p>
      </TextTip>
    );
  }

  return (
    <TextTip color="blue" className="mb-2">
      <span>
        See our{' '}
        <a
          className="hyperlink"
          href={helpLink}
          target="_blank"
          rel="noreferrer"
        >
          documentation for obtaining {title} credentials.
        </a>{' '}
        Any credentials that you set up will be usable by all admin users{' '}
        (although the actual values themselves cannot be viewed).
      </span>
    </TextTip>
  );
}

function getForm(credentialType: CredentialType) {
  switch (credentialType) {
    case CredentialType.GOOGLE_CLOUD:
      return GCPCredentialsForm;

    case CredentialType.AWS:
      return AWSCredentialsForm;

    case CredentialType.AZURE:
      return AzureCredentialsForm;

    case CredentialType.SSH:
      return SSHCredentialsForm;

    case CredentialType.OMNI:
      return OmniCredentialsForm;

    case CredentialType.CIVO:
    case CredentialType.DIGITAL_OCEAN:
    case CredentialType.LINODE:
    default:
      return APICredentialsForm;
  }
}
