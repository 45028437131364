import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';

import { OmniMachineDetails } from '../types';

import { queryKeys } from './query-keys';

export function useTalosMachine<T = OmniMachineDetails>({
  name,
  credentialId,
  queryOptions,
}: {
  name: string;
  credentialId: number;
  queryOptions?: UseQueryOptions<OmniMachineDetails, unknown, T, string[]>;
}) {
  return useQuery({
    queryKey: queryKeys.machine(name),
    queryFn: () => getMachine(name, credentialId),
    select: queryOptions?.select,
  });
}

async function getMachine(name: string, credentialId: number) {
  try {
    const { data } = await axios.get<OmniMachineDetails>(
      `/omni/${credentialId}/machine/${name}`
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error);
  }
}
