import { K8sDistributionType } from '@/react/portainer/environments/types';
import Microk8s from '@/assets/ico/vendor/microk8s.svg?c';
import Omni from '@/assets/ico/vendor/omni.svg?c';

import { BoxSelector, BoxSelectorOption } from '@@/BoxSelector';

interface Props {
  k8sDistributionType: K8sDistributionType;
  onChange(value: K8sDistributionType): void;
}

interface K8sInstallOption extends BoxSelectorOption<K8sDistributionType> {
  id: K8sDistributionType;
  value: K8sDistributionType;
}

const k8sInstallOptions: K8sInstallOption[] = [
  {
    id: K8sDistributionType.OMNI,
    icon: Omni,
    label: 'Talos Kubernetes',
    description:
      'Omni Kubernetes management platform, simplifying the creation and management of Kubernetes clusters',
    value: K8sDistributionType.OMNI,
    iconType: 'logo',
  },
  {
    id: K8sDistributionType.MICROK8S,
    icon: Microk8s,
    label: 'MicroK8s',
    description: 'Lightweight Kubernetes',
    value: K8sDistributionType.MICROK8S,
    iconType: 'logo',
  },
] as const;

export function K8sInstallProvidersSelector({
  onChange,
  k8sDistributionType: provider,
}: Props) {
  return (
    <BoxSelector
      radioName="kaas-type"
      data-cy="kaasCreateForm-providerSelect"
      options={k8sInstallOptions}
      onChange={(provider: K8sDistributionType) => onChange(provider)}
      value={provider}
    />
  );
}
