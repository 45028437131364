import { useField } from 'formik';

import { FormControl } from '@@/form-components/FormControl';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { InlineLoader } from '@@/InlineLoader';

import { SupportDebugSettings } from '../../types';

const fieldKey = 'debugLogEnabled';

export function EnableDebugLogSwitch({ isLoading }: { isLoading: boolean }) {
  const [inputProps, meta, helpers] =
    useField<SupportDebugSettings['debugLogEnabled']>(fieldKey);

  return (
    <FormControl
      inputId="debug_log"
      label="Enable debug log"
      tooltip="Set the server log level to debug to help troubleshoot issues."
      size="small"
      errors={meta.error}
    >
      {isLoading ? (
        <InlineLoader>Loading...</InlineLoader>
      ) : (
        <Switch
          id="debug_log"
          data-cy="enable-openai-integration-switch"
          name={fieldKey}
          className="space-right"
          checked={inputProps.value}
          onChange={handleChange}
        />
      )}
    </FormControl>
  );

  function handleChange(enable: boolean) {
    helpers.setValue(enable);
  }
}
