import { useCallback } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react';

import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';

import { PageHeader } from '@@/PageHeader';
import { LogViewer } from '@@/LogViewer';

import { getMachineLogs } from '../queries/useMachineLogs';
import { useTalosMachines } from '../queries/useTalosMachines';

export function MachineLogsView() {
  const {
    params: { nodeName },
  } = useCurrentStateAndParams();
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const omniMachinesQuery = useTalosMachines({
    credentialId,
    params: { clusterName: environment?.Name },
  });
  const machines = omniMachinesQuery.data || [];
  const nodeNameToMachineNameMap = machines.reduce<Record<string, string>>(
    (acc, machine) => {
      const nodeName =
        machine.spec?.platform_metadata?.hostname ||
        machine.spec?.network?.hostname;
      if (!nodeName) {
        return acc;
      }
      acc[nodeName] = machine.machineName;
      return acc;
    },
    {}
  );
  const machineName = nodeNameToMachineNameMap[nodeName];

  const getLogsFn = useCallback(
    () => getMachineLogs({ credentialId, machineName }),
    [credentialId, machineName]
  );
  const breadcrumbs = [
    { label: 'Cluster information', link: 'kubernetes.cluster' },
    nodeName,
    'Logs',
  ];

  return (
    <>
      <PageHeader title="Machine logs" breadcrumbs={breadcrumbs} reload />

      <LogViewer
        resourceType="docker-container"
        resourceName={machineName}
        getLogsFn={getLogsFn}
        hideFetch
        hideLines
        hideTimestamp
      />
    </>
  );
}
