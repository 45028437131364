import { Formik, Form } from 'formik';

import { notifySuccess } from '@/portainer/services/notifications';
import { useAuthorizations } from '@/react/hooks/useUser';
import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { semverCompare } from '@/react/common/semver-utils';

import { confirm } from '@@/modals/confirm';
import { Card } from '@@/Card';
import { FormControl } from '@@/form-components/FormControl';
import { LoadingButton } from '@@/buttons';
import { ModalType } from '@@/modals';
import { PortainerSelect } from '@@/form-components/PortainerSelect';
import { Alert } from '@@/Alert';
import { TextTip } from '@@/Tip/TextTip';

import { useUpdateCluster } from '../../omni/queries/useUpdateCluster';
import { OmniClusterUpgradePhase } from '../../omni/types';
import { useOmniClusterUpgradeStatus } from '../../omni/queries/useOmniClusterStatus';

import { UpdateOmniClusterPayload } from './types';

export type TalosUpdateFormValues = {
  talosVersion: string;
};

export function UpdateTalosVersionForm() {
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const clusterStatusQuery = useOmniClusterUpgradeStatus(
    'talos',
    environmentQuery.data?.Name ?? '',
    credentialId
  );
  const talosVersion = `v${
    clusterStatusQuery.data?.current_upgrade_version ||
    clusterStatusQuery.data?.last_upgrade_version ||
    ''
  }`;
  const talosCurrentVersionOption = {
    value: talosVersion,
    label: `${talosVersion} (current)`,
  };
  const talosUpgradeVersionOptions =
    clusterStatusQuery.data?.upgrade_versions?.map((version) => ({
      label: `v${version}`,
      value: `v${version}`,
    }));
  const talosVersionOptions = [
    talosCurrentVersionOption,
    ...(talosUpgradeVersionOptions ?? []),
  ].sort((a, b) => semverCompare(a.value, b.value));
  const phase = clusterStatusQuery.data?.phase;

  const isNewVersionAvailable = talosVersion
    ? talosVersionOptions.some(
        (version) => semverCompare(version.value, talosVersion) > 0
      )
    : false;
  const { authorized: isAllowed } = useAuthorizations(['K8sClusterW']);
  const initialValues: TalosUpdateFormValues = {
    talosVersion,
  };
  const upgradeClusterMutation = useUpdateCluster(credentialId);

  if (clusterStatusQuery.isError) {
    return (
      <Card>
        <Alert color="error">Unable to get Talos versions</Alert>
      </Card>
    );
  }

  if (environmentQuery.isError) {
    return (
      <Card>
        <Alert color="error">Unable to load environment</Alert>
      </Card>
    );
  }

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpgradeCluster}
        validateOnMount
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="form-horizontal">
            <FormControl
              label="Talos version"
              tooltip="Talos version running on the cluster."
              inputId="talosVersion"
              isLoading={clusterStatusQuery.isInitialLoading}
              loadingText="Loading Talos versions..."
            >
              <PortainerSelect
                options={talosVersionOptions}
                value={values.talosVersion}
                onChange={(selectedVersion: string) =>
                  setFieldValue('talosVersion', selectedVersion)
                }
                data-cy="omniKubeVersionSelect"
                placeholder="Talos version"
                disabled={!isAllowed}
              />
              {isNewVersionAvailable && (
                <TextTip color="blue">New Talos version(s) available!</TextTip>
              )}
            </FormControl>
            {isAllowed && (
              <LoadingButton
                isLoading={isSubmitting}
                data-cy="upgrade-cluster-button"
                loadingText="Upgrading..."
                type="submit"
                color="secondary"
                className="!ml-0"
                onClick={() => {}}
                disabled={
                  values.talosVersion === talosVersion ||
                  upgradeClusterMutation.isLoading ||
                  phase !== OmniClusterUpgradePhase.DONE
                }
              >
                Update Talos version
              </LoadingButton>
            )}
          </Form>
        )}
      </Formik>
    </Card>
  );

  async function handleUpgradeCluster(values: TalosUpdateFormValues) {
    if (!environment) return;
    const confirmed = await confirm({
      title: 'Are you sure?',
      message:
        'Are you sure you want to upgrade the cluster? This may cause the cluster to be unavailable during the upgrade process.',
      cancelButtonLabel: 'Cancel',
      modalType: ModalType.Warn,
    });
    if (confirmed) {
      const payload: UpdateOmniClusterPayload = {
        cluster: {
          kind: 'Cluster',
          name: environment.Name,
          talos: {
            version: values.talosVersion,
          },
        },
      };

      await upgradeClusterMutation.mutateAsync(payload, {
        onSuccess: () => {
          notifySuccess('Success', 'Cluster Talos upgrade started');
        },
      });
    }
  }
}
