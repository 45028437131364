import { useQueryClient, useMutation } from '@tanstack/react-query';

import { notifySuccess } from '@/portainer/services/notifications';
import { withGlobalError } from '@/react-tools/react-query';

import { createKaasEnvironment } from '../../WizardKaaS/kaas.service';
import { CreateClusterPayload } from '../../WizardKaaS/types';
import { ProvisionOption } from '../types';

export function useInstallK8sCluster() {
  const client = useQueryClient();
  return useMutation(
    ({
      payload,
      provider,
    }: {
      payload: CreateClusterPayload;
      provider: ProvisionOption;
    }) => createKaasEnvironment(payload, provider),
    {
      onSuccess: (_, { provider }) => {
        notifySuccess('Success', 'K8s installation started');
        client.cancelQueries(['cloud', provider, 'info']);
        return client.invalidateQueries(['environments']);
      },
      ...withGlobalError('Unable to create K8s environment'),
    }
  );
}
