import { useEffect, useState } from 'react';

import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';

import { Alert } from '@@/Alert';

import { useOmniClusterUpgradeStatus } from '../../omni/queries/useOmniClusterStatus';
import { OmniClusterUpgradePhase, StatusType } from '../../omni/types';

import { PhaseAlertMap } from './types';

const idleRefecthInterval = 15 * 1000;
const updatingRefetchInterval = 5 * 1000;

type Props = {
  statusType: StatusType;
  phaseAlertMap: PhaseAlertMap;
};

export function OmniUpgradeStatus({ statusType, phaseAlertMap }: Props) {
  const [refetchInterval, setRefetchInterval] =
    useState<number>(idleRefecthInterval);
  const environmentQuery = useCurrentEnvironment();
  const credentialId = environmentQuery.data?.CloudProvider?.CredentialID;
  const clusterQuery = useOmniClusterUpgradeStatus(
    statusType,
    environmentQuery.data?.Name ?? '',
    credentialId,
    { refetchInterval }
  );
  const { phase, status, step } = clusterQuery.data ?? {};

  // Set refetch interval based on cluster phase (refetch more often when updating)
  useEffect(() => {
    if (
      phase === OmniClusterUpgradePhase.REVERTING ||
      phase === OmniClusterUpgradePhase.UPGRADING
    ) {
      setRefetchInterval(updatingRefetchInterval);
      return;
    }
    setRefetchInterval(idleRefecthInterval);
  }, [phase]);

  if (phase === OmniClusterUpgradePhase.DONE || phase === undefined) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Alert {...phaseAlertMap[phase]}>
      {(!!status || !!step) && <p>{formatStatusMessage(status, step)}</p>}
    </Alert>
  );

  function formatStatusMessage(status?: string, step?: string) {
    if (status && step) return `${status}: ${step}`;
    if (status) return status;
    if (step) return step;
    return '';
  }
}
