import { OmniMachineDetails, OmniMachineFormValues } from './types';

export function getMachineFormValue(
  machineName: string,
  machine?: OmniMachineDetails,
  machineFormValues?: OmniMachineFormValues[]
): OmniMachineFormValues {
  const machineFormValue = machineFormValues?.find(
    (value) => value.name === machineName
  );
  // don't override the machine form value if it already exists
  if (machineFormValue) {
    return machineFormValue;
  }
  // if there are machine details, use them to populate the form value
  if (machine) {
    return {
      name: machine.machineName,
      talosVersion: machine.spec.talos_version,
      hostname: machine.spec.network?.hostname ?? machine.machineName,
      applyConfig: false,
      nameservers: [],
      interfaces: [
        {
          interface: machine.spec.network?.network_links[0].linux_name ?? '',
          addresses: machine.spec.network?.addresses?.[0]
            ? [machine.spec.network?.addresses[0]]
            : [],
          routes: machine.spec.network?.default_gateways[0]
            ? [
                {
                  gateway: machine.spec.network?.default_gateways[0],
                  network: '0.0.0.0/0',
                },
              ]
            : [],
        },
      ],
    };
  }
  // otherwise, use empty defaults
  return {
    name: machineName,
    hostname: machineName,
    applyConfig: false,
    nameservers: [],
    interfaces: [
      {
        addresses: [],
        routes: [],
      },
    ],
  };
}
