import { useRouter } from '@uirouter/react';
import { useMutation } from '@tanstack/react-query';

import * as notifications from '@/portainer/services/notifications';
import type { EnvironmentId } from '@/react/portainer/environments/types';

import { DeleteButtonWithRemoveVolume } from '@@/buttons/DeleteButtonWithRemoveVolume';

import { removeStack } from './stacks.service';
import { StackInAsyncSnapshot } from './types';

interface Props {
  selectedItems: StackInAsyncSnapshot[];
  endpointId: EnvironmentId;
}

export function StacksDatatableActions({ selectedItems, endpointId }: Props) {
  const removeStackMutation = useMutation(
    ({ stackId, removeVolumes }: { stackId: number; removeVolumes: boolean }) =>
      removeStack(endpointId, stackId, removeVolumes)
  );

  const router = useRouter();

  const selectedItemCount = selectedItems.length;

  async function removeSelectedStacks(
    stacks: StackInAsyncSnapshot[],
    removeVolumes: boolean
  ) {
    for (let i = 0; i < stacks.length; i += 1) {
      const stack = stacks[i];

      if (stack.Metadata.stackId) {
        try {
          await removeStackMutation.mutateAsync({
            stackId: +stack.Metadata.stackId,
            removeVolumes,
          });
          notifications.success(
            'Stack removal successfully planned',
            stack.StackName ? stack.StackName : stack.Names[0]
          );
        } catch (err) {
          notifications.error(
            'Failure',
            err as Error,
            'Unable to schedule stack removal'
          );
        }
      }
    }

    router.stateService.reload();
  }

  return (
    <DeleteButtonWithRemoveVolume
      confirmMessage="Do you want to remove the selected stack(s)? Associated services will be removed as well."
      onConfirmed={(removeVolumes) =>
        removeSelectedStacks(selectedItems, removeVolumes)
      }
      disabled={selectedItemCount === 0 || removeStackMutation.isLoading}
      data-cy="edge-devices-stacks-remove-button"
    />
  );
}
