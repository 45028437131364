import { useState } from 'react';
import { Formik } from 'formik';

import { Credential } from '@/react/portainer/settings/sharedCredentials/types';
import {
  Environment,
  K8sDistributionType,
} from '@/react/portainer/environments/types';
import { useCustomTemplates } from '@/react/portainer/templates/custom-templates/queries/useCustomTemplates';
import { formatNodeIPs } from '@/react/kubernetes/cluster/microk8s/utils';
import { StackType } from '@/react/common/stacks/types';

import { BoxSelectorOption } from '@@/BoxSelector';
import { Loading } from '@@/Widget';

import { AnalyticsStateKey } from '../../types';

import { useInstallK8sCluster } from './useInstallK8sCluster';
import { useMicroK8sOptions } from './useMicrok8sOptions';
import {
  CreateMicrok8sClusterPayload,
  Microk8sInstallFormValues,
} from './types';
import { Microk8sCreateClusterInnerForm } from './Microk8sCreateClusterInnerForm';
import { useMicroK8sValidation } from './useMicroK8sValidation';

interface Props {
  onCreate(environment: Environment, analytics: AnalyticsStateKey): void;
  credentials: Credential[];
}

type K8sInstallValue = 'microk8s' | 'omni';

export interface K8sInstallOption extends BoxSelectorOption<K8sInstallValue> {
  id: K8sInstallValue;
  value: K8sInstallValue;
}

const initialValues: Microk8sInstallFormValues = {
  name: '',
  credentialId: 0,
  meta: {
    groupId: 1,
    tagIds: [],
  },
  microk8s: {
    masterNodes: [''],
    workerNodes: [''],
    addons: [],
    kubernetesVersion: '1.30/stable',
    offlineInstall: false,
  },
};

export function Microk8sCreateClusterForm({ onCreate, credentials }: Props) {
  const [isSSHTestSuccessful, setIsSSHTestSuccessful] = useState<
    boolean | undefined
  >(undefined);
  const installK8sClusterMutation = useInstallK8sCluster();

  // custom templates fetching
  const customTemplatesQuery = useCustomTemplates({
    select: (templates) =>
      templates.filter((t) => t.Type === StackType.Kubernetes),
  });
  const customTemplates = customTemplatesQuery.data ?? [];
  const { data: microk8sOptions, ...microk8sOptionsQuery } =
    useMicroK8sOptions();

  const validation = useMicroK8sValidation(
    microk8sOptions?.availableAddons ?? []
  );

  if (microk8sOptionsQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validation}
      validateOnMount
      enableReinitialize
    >
      <Microk8sCreateClusterInnerForm
        credentials={credentials}
        customTemplates={customTemplates}
        isSubmitting={installK8sClusterMutation.isLoading}
        isSSHTestSuccessful={isSSHTestSuccessful}
        setIsSSHTestSuccessful={setIsSSHTestSuccessful}
      />
    </Formik>
  );

  function handleSubmit(
    values: Microk8sInstallFormValues,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: string | string[],
        shouldValidate?: boolean
      ) => void;
    }
  ) {
    const payload = formatMicrok8sPayload(values);

    installK8sClusterMutation.mutate(
      { payload, provider: K8sDistributionType.MICROK8S },
      {
        onSuccess: (environment) => {
          onCreate(environment, 'kubernetesInstallOmni');
          setFieldValue('name', '');
          setFieldValue('microk8s.masterNodes', ['']);
          setFieldValue('microk8s.workerNodes', ['']);
          setIsSSHTestSuccessful(undefined);
        },
      }
    );
  }
}

function formatMicrok8sPayload({
  microk8s: {
    masterNodes,
    workerNodes,
    addons,
    kubernetesVersion,
    offlineInstall,
  },
  ...values
}: Microk8sInstallFormValues): CreateMicrok8sClusterPayload {
  return {
    ...values,
    masterNodes: formatNodeIPs(masterNodes),
    workerNodes: formatNodeIPs(workerNodes),
    addons,
    kubernetesVersion,
    offlineInstall,
  };
}
