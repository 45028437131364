import { Formik } from 'formik';

import { Alert } from '@@/Alert';

import { OmniAddNodesFormValues } from '../types';

import { omniAddNodesFormValidation } from './omniAddNodesFormValidation';
import { useOmniAddNodesFormData } from './useOmniAddNodesFormData';
import { useOmniAddNodesFormSubmit } from './useOmniAddNodesFormSubmit';
import { OmniAddNodesInnerForm } from './OmniAddNodesInnerForm';

const initialValues: OmniAddNodesFormValues = {
  machineSets: [
    {
      name: 'Control Plane',
      role: 'control-plane',
      machines: [],
    },
    {
      name: 'Main worker pool',
      role: 'worker',
      machines: [],
    },
  ],
};

export function OmniAddNodesForm() {
  const {
    environment,
    credentialId,
    talosVersion,
    machineOptions,
    isError,
    isInitialLoading,
  } = useOmniAddNodesFormData();

  const { handleSubmit } = useOmniAddNodesFormSubmit(environment, credentialId);

  if (isError) {
    return <Alert color="error">Unable to load machine options.</Alert>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={omniAddNodesFormValidation(talosVersion)}
      validateOnMount
      enableReinitialize
    >
      <OmniAddNodesInnerForm
        credentialId={credentialId}
        talosVersion={talosVersion}
        machineOptions={machineOptions}
        isLoadingMachines={isInitialLoading}
      />
    </Formik>
  );
}
