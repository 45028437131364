import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { formatMachineOptions } from '@/react/kubernetes/cluster/omni/components/MachineSetNodeSelect';

import { useTalosMachines } from '../../omni/queries/useTalosMachines';
import { useOmniCluster } from '../../omni/queries/useOmniCluster';

export function useOmniAddNodesFormData() {
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;

  const clusterQuery = useOmniCluster(environment?.Name ?? '', credentialId, {
    enabled: !!environment?.Name,
  });

  const machineOptionsQuery = useTalosMachines({
    credentialId,
    queryOptions: {
      select: (machines) => ({
        options: formatMachineOptions(machines),
        machines,
      }),
    },
    params: {
      isAvailable: 'true',
    },
  });

  return {
    environment,
    credentialId,
    clusterQuery,
    machineOptionsQuery,
    talosVersion: clusterQuery.data?.metadata.talos_version,
    machineOptions: machineOptionsQuery.data?.options ?? [],
    isLoading:
      environmentQuery.isLoading ||
      clusterQuery.isLoading ||
      machineOptionsQuery.isLoading,
    isError:
      environmentQuery.isError ||
      clusterQuery.isError ||
      machineOptionsQuery.isError,
    isInitialLoading:
      machineOptionsQuery.isInitialLoading || clusterQuery.isInitialLoading,
  };
}
