import { useMemo } from 'react';

import { StorageClass } from '@/react/portainer/environments/types';
import { Registry } from '@/react/portainer/registries/types/registry';

import { StorageQuotaFormValues } from '../components/NamespaceForm/StorageQuotaFormSection/types';
import { NamespaceFormValues, PortainerNamespace } from '../types';
import { Annotation } from '../../annotations/types';
import { megaBytesValue, parseCPU } from '../resourceQuotaUtils';
import { IngressControllerClassMap } from '../../cluster/ingressClass/types';

const KubernetesPortainerResourceQuotaStorageSuffix =
  '.storageclass.storage.k8s.io/requests.storage';

function computeStorageQuotaFormValues(
  namespace: PortainerNamespace | undefined,
  storageClasses: StorageClass[]
): StorageQuotaFormValues[] {
  const storageRequestKeys = Object.keys(
    namespace?.ResourceQuota?.spec?.hard || {}
  ).filter((key) =>
    key.endsWith(KubernetesPortainerResourceQuotaStorageSuffix)
  );

  const storageClassesWithRequests: StorageQuotaFormValues[] =
    storageRequestKeys.map((key) => {
      const sizeInGB = namespace?.ResourceQuota?.spec?.hard?.[key] ?? '0';
      const className = key.replace(
        KubernetesPortainerResourceQuotaStorageSuffix,
        ''
      );
      return {
        className,
        size: `${parseInt(sizeInGB, 10)}`,
        enabled: true,
        sizeUnit: 'G',
      };
    });

  const storageClassesWithoutRequests: StorageQuotaFormValues[] = storageClasses
    .filter(
      (storageClass) =>
        !storageClassesWithRequests.some(
          (enabled) => enabled.className === storageClass.Name
        )
    )
    .map((val) => ({
      className: val.Name,
      size: '0',
      enabled: false,
      sizeUnit: 'G',
    }));

  return [...storageClassesWithRequests, ...storageClassesWithoutRequests];
}

function computeAnnotations(
  namespaceAnnotations: Record<string, string> | undefined
): Annotation[] {
  return (
    Object.entries(namespaceAnnotations || {}).map(([key, value], index) => ({
      key,
      value,
      id: `${key}-${value}-${index}`,
    })) || []
  );
}

interface ComputeInitialValuesParams {
  namespaceName: string;
  environmentId: number;
  storageClasses?: StorageClass[];
  namespace?: PortainerNamespace;
  registries?: Registry[];
  ingressClasses?: IngressControllerClassMap[];
}

export function computeInitialValues({
  namespaceName,
  environmentId,
  namespace,
  storageClasses,
  registries,
  ingressClasses,
}: ComputeInitialValuesParams): NamespaceFormValues | null {
  if (!namespace) {
    return null;
  }

  const storageQuota = computeStorageQuotaFormValues(
    namespace,
    storageClasses ?? []
  );
  const annotations = computeAnnotations(namespace?.Annotations ?? {});

  const loadBalancerQuota =
    namespace.ResourceQuota?.spec?.hard?.['services.loadbalancers'];
  const memory = namespace.ResourceQuota?.spec?.hard?.['requests.memory'] ?? '';
  const cpu = namespace.ResourceQuota?.spec?.hard?.['requests.cpu'] ?? '';

  const registriesUsed = registries?.filter(
    (registry) =>
      registry.RegistryAccesses?.[`${environmentId}`]?.Namespaces.includes(
        namespaceName
      )
  );

  return {
    name: namespaceName,
    annotations,
    loadBalancerQuota: {
      enabled: !!loadBalancerQuota,
      limit: loadBalancerQuota ? Number(loadBalancerQuota) : 0,
    },
    ingressClasses: ingressClasses ?? [],
    resourceQuota: {
      enabled: !!memory || !!cpu,
      memory: `${megaBytesValue(memory)}`,
      cpu: `${parseCPU(cpu)}`,
    },
    registries: registriesUsed ?? [],
    storageQuota,
  };
}

export function useNamespaceFormValues({
  namespaceName,
  environmentId,
  storageClasses,
  namespace,
  registries,
  ingressClasses,
}: ComputeInitialValuesParams): NamespaceFormValues | null {
  return useMemo(
    () =>
      computeInitialValues({
        namespaceName,
        environmentId,
        storageClasses,
        namespace,
        registries,
        ingressClasses,
      }),
    [
      storageClasses,
      namespace,
      registries,
      namespaceName,
      ingressClasses,
      environmentId,
    ]
  );
}
