// control-plane and worker nodes, plus user defined machine sets
export interface OmniClusterMachineSet {
  name: string;
  role: OmniMachineSetRole;
  machines: OmniMachineFormValues[];
}

export type OmniMachineSetRole = 'control-plane' | 'worker';

/**
 * The machine details used in the create cluster form and payload
 */
export interface OmniMachineFormValues {
  name: string;
  talosVersion?: string; // used to validate the talos version is close enough to the cluster version (within 1 minor version difference)
  hostname?: string;
  nameservers: string[];
  interfaces: MachineInterface[];
  applyConfig: boolean;
}

export interface OmniMachinePayload {
  name: string;
  hostname?: string;
  nameservers?: string[];
  interfaces?: MachineInterface[];
}

export interface MachineInterface {
  interface?: string;
  addresses: string[];
  routes: MachineRoute[];
}

export interface MachineRoute {
  network?: string;
  gateway?: string;
}

/**
 * The machine details returned by the Talos API.
 */
export type OmniMachineDetails = {
  machineName: string;
  labels: Record<string, string>;
  spec: {
    talos_version?: string;
    network?: {
      hostname?: string;
      addresses: string[];
      default_gateways: string[];
      network_links: MachineNetworkLink[];
    };
    platform_metadata?: {
      hostname?: string;
    };
  };
};

type MachineNetworkLink = {
  linux_name?: string; // network interface name option
  hardware_address?: string;
  speed_mbps?: number;
  link_up?: boolean;
  description?: string;
};

/**
 * OmniClusterUpgradePhase represents the different phases of an OmniCluster upgrade. Don't confuse with OmniClusterPhase.
 */
export enum OmniClusterUpgradePhase {
  UNKNOWN = 0,
  UPGRADING = 1,
  DONE = 2,
  FAILED = 3,
  REVERTING = 4,
}

/**
 * OmniClusterPhase represents the different phases of an OmniCluster. Don't confuse with OmniClusterUpgradePhase.
 */
export enum OmniClusterPhase {
  UNKNOWN = 0,
  SCALING_UP = 1,
  SCALING_DOWN = 2,
  RUNNING = 3,
  DESTROYING = 4,
}

export type OmniClusterInfo = {
  status: {
    available: boolean;
    machines: {
      total: number;
      healthy: number;
    };
    phase: OmniClusterPhase;
    ready: boolean;
    kubernetesAPIReady: boolean;
    controlplaneReady: boolean;
    has_connected_control_planes: boolean;
  };
  metadata: {
    kubernetes_version: string;
    talos_version: string;
    features: Record<string, unknown>;
  };
};

export type OmniClusterUpgradeStatus = {
  phase: OmniClusterUpgradePhase;
  step?: string;
  status?: string;
  last_upgrade_version: string;
  current_upgrade_version: string;
  upgrade_versions: string[];
};

export type StatusType = 'k8s' | 'talos';
