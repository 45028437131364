import { File, RefreshCw } from 'lucide-react';

import { Button } from '@@/buttons';
import { SearchBar } from '@@/datatables/SearchBar';
import { Checkbox } from '@@/form-components/Checkbox';
import { SwitchField } from '@@/form-components/SwitchField';
import { Input, Select } from '@@/form-components/Input';
import { SinceOptions } from '@@/LogViewer/LogController/data';
import { FullScreenButton } from '@@/LogViewer/LogController/FullScreenButton/FullScreenButton';
import { CopyLogsButton } from '@@/LogViewer/LogController/CopyLogsButton/CopyLogsButton';
import { DownloadLogsButton } from '@@/LogViewer/LogController/DownloadLogsButton/DownloadLogsButton';
import { SearchStatus } from '@@/LogViewer/LogController/SearchStatus/SearchStatus';
import { WidgetTitle } from '@@/Widget';

import './LogController.css';

import { useLogViewerContext } from '../context';

export function LogController() {
  const {
    searchStatus,
    controllerStates,
    hideFetch,
    hideLines,
    hideTimestamp,
    logsQuery,
  } = useLogViewerContext();

  const {
    keyword,
    setKeyword,
    filter,
    setFilter,
    autoRefresh,
    setAutoRefresh,
    since,
    setSince,
    tail,
    setTail,
    showTimestamp,
    setShowTimestamp,
    wrapLine,
    setWrapLine,
    showLineNumbers,
    setShowLineNumbers,
  } = controllerStates;

  return (
    <>
      <WidgetTitle icon={File} title="Logs">
        <div className="tool-bar-segment">
          <SearchBar
            value={keyword}
            onChange={setKeyword}
            data-cy="log-viewer-search-input"
          >
            {controllerStates.keyword && (
              <SearchStatus
                searchIndicator={searchStatus}
                data-cy="log-viewer-search-status"
              />
            )}
          </SearchBar>

          <div className="vertical-center">
            <Checkbox
              id="filter"
              data-cy="log-viewer-filter-switch"
              checked={filter}
              onChange={(e) => setFilter(e.target.checked)}
            />
            <span>Filter search results</span>
          </div>

          <CopyLogsButton />

          <DownloadLogsButton />
        </div>
      </WidgetTitle>

      <div className="tool-bar">
        <div className="tool-bar-segment">
          <div className="vertical-center">
            <SwitchField
              label=""
              checked={autoRefresh}
              onChange={setAutoRefresh}
              data-cy="log-viewer-auto-refresh-switch"
            />
            <span>Auto refresh</span>
          </div>

          <div className="vertical-center">
            <Button
              onClick={() => logsQuery.refetch()}
              disabled={autoRefresh || logsQuery.isFetching}
              color="none"
              icon={RefreshCw}
              title="Refresh"
              data-cy="log-viewer-refresh-button"
            />
          </div>

          {!hideFetch && (
            <div className="vertical-center">
              <span>Fetch</span>
              <Select
                value={since}
                onChange={(e) => {
                  setSince(parseInt(e.currentTarget.value, 10));
                }}
                options={SinceOptions}
                data-cy="log-viewer-since-select"
                id="log-viewer-since-select"
              />
            </div>
          )}

          {!hideLines && (
            <div className="vertical-center">
              <span>Lines</span>
              <Input
                type="number"
                value={tail}
                min={1}
                onChange={(e) => {
                  const t = parseInt(e.target.value, 10);
                  setTail(t || '');
                }}
                className="line-number"
                data-cy="log-viewer-tail-input"
              />
            </div>
          )}

          {!hideTimestamp && (
            <div className="vertical-center">
              <Checkbox
                id="showTimestamp"
                data-cy="log-viewer-show-timestamp-switch"
                checked={showTimestamp}
                onChange={(e) => setShowTimestamp(e.target.checked)}
              />
              <span>Show timestamp</span>
            </div>
          )}

          <div className="vertical-center">
            <Checkbox
              id="showLineNumber"
              data-cy="log-viewer-show-line-number-switch"
              checked={showLineNumbers}
              onChange={(e) => setShowLineNumbers(e.target.checked)}
            />
            <span>Show line numbers</span>
          </div>

          <div className="vertical-center">
            <Checkbox
              id="wrapLine"
              data-cy="log-viewer-wrap-line-switch"
              checked={wrapLine}
              onChange={(e) => setWrapLine(e.target.checked)}
            />
            <span>Wrap line</span>
          </div>
        </div>

        <div className="tool-bar-segment">
          <FullScreenButton />
        </div>
      </div>
    </>
  );
}
