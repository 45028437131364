import {
  object,
  string,
  boolean,
  array,
  number,
  SchemaOf,
  TestContext,
} from 'yup';

import { isBE } from '@/react/portainer/feature-flags/feature-flags.service';
import { K8sNodeLimits } from '@/react/kubernetes/namespaces/queries/useResourceLimitsQuery';

import { IngressControllerClassMap } from '../../ingressClass/types';

import { ConfigureFormValues } from './types';

// Define Yup schema for DeploymentOptions
const deploymentOptionsSchema = object().shape({
  overrideGlobalOptions: boolean(),
  hideAddWithForm: boolean(),
  hideWebEditor: boolean(),
  hideFileUpload: boolean(),
});

// Define Yup schema for AccessMode
const accessModeSchema = object().shape({
  Description: string().required(),
  Name: string().required(),
  selected: boolean().required(),
});

// Define Yup schema for StorageClassFormValues
const storageClassFormValuesSchema = array()
  .of(
    object().shape({
      Name: string().required(),
      AccessModes: array().of(accessModeSchema).required(),
      Provisioner: string().required(),
      AllowVolumeExpansion: boolean().required(),
      selected: boolean().required(),
    })
  )
  .test(
    // invalid if any storage class is not selected or
    // if it's selected and at least one access mode is selected
    'accessModes',
    'Shared access policy configuration required.',
    (storageClasses) => {
      const isValid = storageClasses?.every(
        (value) =>
          !value.selected ||
          (value.AccessModes && value.AccessModes?.length > 0)
      );
      return isValid || false;
    }
  );

// Define Yup schema for EndpointChangeWindow
const endpointChangeWindowSchema = object().shape({
  Enabled: boolean().required(),
  StartTime: string().test(
    'startTime should not be the same as endTime',
    'The chosen time configuration is invalid.',
    (value, context) => {
      const { EndTime, Enabled } = context.parent;
      return !Enabled || value !== EndTime;
    }
  ),
  EndTime: string(),
});

// Define Yup schema for IngressControllerClassMap
const ingressControllerClassMapSchema: SchemaOf<IngressControllerClassMap> =
  object().shape({
    Name: string().required(),
    ClassName: string().required(),
    Type: string().required(),
    Availability: boolean().required(),
    New: boolean().required(),
    Used: boolean().required(),
  });

// Define Yup schema for ConfigureFormValues
export function getConfigureValidationSchema(
  clusterResourceLimits?: K8sNodeLimits,
  clusterResourceLimitsMinusQuotas?: K8sNodeLimits,
  isMissingResourceQuotas?: boolean
): SchemaOf<ConfigureFormValues> {
  return object({
    useLoadBalancer: boolean().required(),
    useServerMetrics: boolean().required(),
    enableResourceOverCommit: boolean()
      .test(
        'missingQuotas',
        'One or more existing non-system namespaces already have resource assignment turned off. You must turn resource assignment on in them before you can turn over-commit off.',
        (value?: boolean) => value || !isMissingResourceQuotas
      )
      .required(),
    resourceOverCommitPercentage: number()
      .max(100, 'Must be less than 100.')
      .when(
        'enableResourceOverCommit',
        // when disabled, then the min is 1
        {
          is: false,
          then: number().min(1, 'Must be at least 1.'),
        }
      )
      .required('System resource reservation percentage is required.'),
    restrictDefaultNamespace: boolean().required(),
    restrictSecrets: boolean().required(),
    restrictStandardUserIngressW: boolean().required(),
    ingressAvailabilityPerNamespace: boolean().required(),
    allowNoneIngressClass: boolean().required(),
    storageClasses: storageClassFormValuesSchema.required(),
    deploymentOptions: deploymentOptionsSchema.nullable(),
    changeWindow: isBE ? endpointChangeWindowSchema.required() : undefined,
    ingressClasses: array().of(ingressControllerClassMapSchema).required(),
    timeZone: string(),
  }).test(
    'resourceOverCommitPercentage',
    (value: unknown, context: TestContext) => {
      const { enableResourceOverCommit, resourceOverCommitPercentage } =
        value as ConfigureFormValues;
      return testLimitExceeded(
        context,
        resourceOverCommitPercentage,
        enableResourceOverCommit,
        clusterResourceLimits,
        clusterResourceLimitsMinusQuotas
      );
    }
  );
}

function testLimitExceeded(
  context: TestContext,
  resourceReservationPercent?: number,
  enableResourceOverCommit?: boolean,
  clusterResourceLimits?: K8sNodeLimits,
  clusterResourceLimitsMinusQuotas?: K8sNodeLimits
) {
  if (
    !resourceReservationPercent ||
    !clusterResourceLimitsMinusQuotas ||
    enableResourceOverCommit ||
    !clusterResourceLimits
  ) {
    return true;
  }
  const resourceReservationDecimal = resourceReservationPercent / 100;
  const reservedAmounts = {
    cpu: clusterResourceLimits.CPU * resourceReservationDecimal,
    memory: clusterResourceLimits.Memory * resourceReservationDecimal,
  };

  const totalCpu = clusterResourceLimitsMinusQuotas.CPU - reservedAmounts.cpu;
  const totalMemory =
    clusterResourceLimitsMinusQuotas.Memory - reservedAmounts.memory;

  if (totalCpu < 0 && totalMemory < 0) {
    return context.createError({
      message:
        'Existing namespaces already have memory and CPU limits exceeding what is available in the cluster. You must reduce amounts in namespaces before you can turn off over-commit.',
      path: 'resourceOverCommitPercentage',
    });
  }

  if (totalCpu < 0) {
    return context.createError({
      message:
        'Existing namespaces already have CPU limits exceeding what is available in the cluster. You must reduce amounts in namespaces before you can turn off over-commit.',
      path: 'resourceOverCommitPercentage',
    });
  }

  if (totalMemory < 0) {
    return context.createError({
      message:
        'Existing namespaces already have memory limits exceeding what is available in the cluster. You must reduce amounts in namespaces before you can turn off over-commit.',
      path: 'resourceOverCommitPercentage',
    });
  }

  return true;
}
