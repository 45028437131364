import { useMemo, useState } from 'react';

import { providerToCredentialTypeMap } from '@/react/portainer/settings/sharedCredentials/types';
import { useCloudCredentials } from '@/react/portainer/settings/sharedCredentials/cloudSettings.service';
import {
  Environment,
  K8sDistributionType,
} from '@/react/portainer/environments/types';
import { CredentialsForm } from '@/react/portainer/settings/sharedCredentials/CreateCredentialsView/CredentialsForm';

import { Link } from '@@/Link';
import { TextTip } from '@@/Tip/TextTip';
import { BoxSelectorOption } from '@@/BoxSelector';
import { Loading } from '@@/Widget';

import { AnalyticsStateKey } from '../types';

import { k8sInstallTitles } from './types';
import { K8sInstallProvidersSelector } from './K8sInstallProvidersSelector';
import { Microk8sCreateClusterForm } from './microk8s/Microk8sCreateClusterForm';
import { OmniCreateClusterForm } from './omni/OmniCreateClusterForm';

interface Props {
  onCreate(environment: Environment, analytics: AnalyticsStateKey): void;
}

type K8sInstallValue = 'microk8s' | 'omni';

export interface K8sInstallOption extends BoxSelectorOption<K8sInstallValue> {
  id: K8sInstallValue;
  value: K8sInstallValue;
}

export function WizardK8sInstall({ onCreate }: Props) {
  const [k8sDistributionType, setK8sDistributionType] =
    useState<K8sDistributionType>(K8sDistributionType.OMNI);

  const credentialType = providerToCredentialTypeMap[k8sDistributionType];
  const credentialsQuery = useCloudCredentials();
  const credentialsForDistribution = useMemo(
    () =>
      credentialsQuery.data?.filter((c) => c.provider === credentialType) ?? [],
    [credentialType, credentialsQuery.data]
  );
  const isCredentialsFound =
    !credentialsQuery.isLoading && credentialsForDistribution.length > 0;

  return (
    <>
      <div className="form-horizontal">
        <K8sInstallProvidersSelector
          k8sDistributionType={k8sDistributionType}
          onChange={setK8sDistributionType}
        />
      </div>
      {credentialsQuery.isLoading && <Loading />}
      {isCredentialsFound && (
        <>
          {k8sDistributionType === K8sDistributionType.OMNI ? (
            <OmniCreateClusterForm
              onCreate={onCreate}
              credentials={credentialsForDistribution}
            />
          ) : null}
          {k8sDistributionType === K8sDistributionType.MICROK8S ? (
            <Microk8sCreateClusterForm
              onCreate={onCreate}
              credentials={credentialsForDistribution}
            />
          ) : null}
        </>
      )}
      {!isCredentialsFound && (
        <>
          <TextTip color="orange">
            No credentials found for {k8sInstallTitles[k8sDistributionType]}.
            Save your credentials below, or in the{' '}
            <Link
              to="portainer.settings.sharedcredentials"
              title="shared credential settings"
              className="hyperlink"
              data-cy="k8sInstallForm-credentialsLink"
            >
              shared credential settings.
            </Link>
          </TextTip>
          <CredentialsForm credentialType={credentialType} />
        </>
      )}
    </>
  );
}
