import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withGlobalError } from '@/react-tools/react-query';

import { SupportDownloadPayload } from './types';

export function useDownloadSupportBundleMutation() {
  return useMutation(downloadSupportBundle, {
    ...withGlobalError('Unable to download support bundle'),
  });
}

async function downloadSupportBundle(payload: SupportDownloadPayload) {
  try {
    const response = await axios.post('support/download', payload, {
      responseType: 'arraybuffer',
    });

    const file = response.data;
    const filename = response.headers['content-disposition'].replace(
      'attachment; filename=',
      ''
    );
    const blob = new Blob([file], { type: 'application/gzip' });
    return saveAs(blob, filename);
  } catch (e) {
    throw parseAxiosError(e, 'Unable to download support bundle');
  }
}
