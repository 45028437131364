import { FormikErrors } from 'formik';

import { FeatureId } from '@/react/portainer/feature-flags/enums';
import { useNamespaceServices } from '@/react/kubernetes/services/useNamespaceServices';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { Authorized } from '@/react/hooks/useUser';

import { FormControl } from '@@/form-components/FormControl';
import { FormSection } from '@@/form-components/FormSection';
import { Input } from '@@/form-components/Input/Input';
import { SwitchField } from '@@/form-components/SwitchField';
import { TextTip } from '@@/Tip/TextTip';

import { ResourceUsageItem } from '../../ResourceUsageItem';

import { LoadBalancerQuotaFormValues } from './types';

interface Props {
  namespace: string;
  values: LoadBalancerQuotaFormValues;
  onChange: (value: LoadBalancerQuotaFormValues) => void;
  errors?: FormikErrors<LoadBalancerQuotaFormValues>;
  isEdit?: boolean;
}

export function LoadBalancerFormSection({
  namespace,
  values: loadBalancerQuota,
  onChange,
  errors,
  isEdit,
}: Props) {
  const environmentId = useEnvironmentId();
  const loadBalancersCountQuery = useLoadBalancersCount(
    environmentId,
    namespace
  );
  return (
    <FormSection title="Load balancers">
      <Authorized authorizations={['K8sResourcePoolDetailsW']}>
        <TextTip color="blue">
          You can set a quota on the number of external load balancers that can
          be created inside this namespace. Set this quota to 0 to effectively
          disable the use of load balancers in this namespace.
        </TextTip>
        <div className="form-group">
          <div className="col-sm-12">
            <SwitchField
              data-cy="k8sNamespaceCreate-loadBalancerQuotaToggle"
              label="Load balancer quota"
              labelClass="col-sm-3 col-lg-2"
              fieldClass="pt-2"
              checked={loadBalancerQuota.enabled}
              onChange={(enabled) => {
                if (enabled) {
                  onChange({ limit: 0, enabled });
                  return;
                }
                onChange({ ...loadBalancerQuota, enabled });
              }}
              featureId={FeatureId.K8S_ANNOTATIONS}
            />
          </div>
        </div>
      </Authorized>
      {loadBalancerQuota.enabled && (
        <div className="pt-5">
          <Authorized authorizations={['K8sResourcePoolDetailsW']}>
            <FormControl
              label="Max load balancers"
              inputId="loadbalancers"
              errors={errors?.limit}
              required
            >
              <Input
                required
                type="number"
                min={0}
                value={loadBalancerQuota.limit}
                onChange={(event) =>
                  onChange({
                    ...loadBalancerQuota,
                    limit: event.target.valueAsNumber,
                  })
                }
                data-cy="k8sNamespaceCreate-maxLoadBalancerInput"
                className="w-1/4"
              />
            </FormControl>
          </Authorized>
          {!!isEdit && (
            <ResourceUsageItem
              value={loadBalancersCountQuery.data ?? 0}
              total={loadBalancerQuota.limit ?? 0}
              label="Load balancer quota"
              annotation={`${loadBalancersCountQuery.data} / ${
                loadBalancerQuota.limit ?? 0
              }`}
            />
          )}
        </div>
      )}
    </FormSection>
  );
}

function useLoadBalancersCount(
  environmentId: EnvironmentId,
  namespace: string
) {
  return useNamespaceServices(environmentId, namespace, {
    select: (services) =>
      services.filter((service) => service.Type === 'LoadBalancer').length,
  });
}
